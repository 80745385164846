import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Row, Col, Button, Spinner, Form, Image, Toast } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Page, InputField } from "@src/components"
import { LockUnlockButton } from "./components/LockUnlockButton"
import { DeviceTabs } from "./tabs/DeviceTabs"
import * as Icon from "react-bootstrap-icons"
import { usePartner } from "@src/partner"
import { Battery } from "./tabs/components"
import { colors } from "@src/constants"
import { PowerSaveButton } from "./components/PowerSaveButton"
import { getTimeDiff } from "@src/utils/datetime"
import { DEVICE_LOADING_STATE, useDevice } from "../useDevice"
import { colorTagStyle } from "../DevicesScreen"

const styles = {
  serialnumber: { marginLeft: ".75rem" },
  verticalDivider: { backgroundColor: colors.gray, width: 1, height: 20 },
}
export const DeviceDetailScreen: React.FC = () => {
  const { t } = useTranslation()
  const { serialnumber } = useParams()
  const { partner } = usePartner()
  const [loadingField, setLoadingField] = useState<boolean>(false)
  const [timeDiff, setTimeDiff] = useState<string>("")
  const { device, patchDevice, loadDevice, loading } = useDevice()
  const [showLockingTimedoutAlert, setShowLockingTimedoutAlert] = useState<boolean>(false)

  useEffect(() => {
    if (partner) loadDevice(serialnumber, DEVICE_LOADING_STATE.load)
  }, [partner, serialnumber])

  useEffect(() => {
    if (device) {
      setTimeDiff(getTimeDiff(new Date(device.powersaving_ends_at)))
    }
  }, [device])

  useEffect(() => {
    const timer = setInterval(() => setTimeDiff(getTimeDiff(new Date(device.powersaving_ends_at))), 60000)
    return () => clearInterval(timer)
  }, [timeDiff])

  const onInputPersist = async (text: string) => {
    setLoadingField(true)
    const newDevice = { ...device, name: text }
    let statusOk = await patchDevice(newDevice)
    setLoadingField(!statusOk)
  }

  return (
    <div>
      {showLockingTimedoutAlert && !loading && (
        <>
          <Toast
            style={{ position: "absolute", top: 0, right: 50, backgroundColor: colors.orangeRed }}
            animation={true}
            autohide={true}
            onClose={() => setShowLockingTimedoutAlert(false)}>
            <Toast.Body>
              <strong>Something went wrong!</strong>
            </Toast.Body>
          </Toast>
        </>
      )}
      <Page>
        {loading == DEVICE_LOADING_STATE.load && (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
        {device && !loading && (
          <div>
            <Row className="mt-2 text-start">
              <Col xs={12} md={9} lg={7} className="d-flex">
                {device?.icon && <Image rounded src={device?.icon} width={65} height={65} className="me-2" />}
                <div style={{ flex: 1 }}>
                  <InputField value={device?.name} onPersist={onInputPersist} loading={loadingField} maxLength={40} />
                  <div className="d-flex align-items-center">
                    <Battery battery_value={device?.battery} charging={device?.charging} />
                    <div className="ms-3" style={styles.verticalDivider} />
                    <Form.Text muted style={styles.serialnumber}>
                      {device?.serialnumber}
                    </Form.Text>
                    {device && device.powersaving_ends_at && (
                      <div className="d-flex flex-row align-items-center">
                        <div className="ms-3" style={styles.verticalDivider} />
                        <div className="ms-2 align-items-center">
                          <Icon.LightningChargeFill />
                          <Form.Text muted className="ms-1">
                            {timeDiff} {t("device_screen.details_tab.time_left")}
                          </Form.Text>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col className="d-flex justify-content-end">
                <div className="d-flex flex-row">
                  <LockUnlockButton
                    serialnumber={serialnumber}
                    showLockingTimedoutAlert={setShowLockingTimedoutAlert}
                  />
                  {partner?.permissions?.can_see_energy_saving && (
                    <PowerSaveButton serialnumbers={[device?.serialnumber]} />
                  )}
                  <Link to={`/${partner?.uuid}/theft/${device.serialnumber}`}>
                    {!device.is_stolen ? (
                      <Button className="ms-2">{t("device_screen.report_theft")}</Button>
                    ) : (
                      <Button className="ms-2">
                        <Icon.Flag style={colorTagStyle(colors.white)} className="me-1" />
                        {t("device_screen.show_theft")}
                      </Button>
                    )}
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <DeviceTabs serialnumber={serialnumber} />
            </Row>
          </div>
        )}
        {!device && loading !== DEVICE_LOADING_STATE.load && (
          <Row>
            <div>{t("device_screen.device_loading_error")}</div>
          </Row>
        )}
      </Page>
    </div>
  )
}
