import { env } from "@src/utils"

export const map = {
  // TODO: For now, the keys are same for both production and staging.
  key: env == "production" ? "AIzaSyA9fBm6M76PHrHao4Q64vSevP1RZpWIc0M" : "AIzaSyA9fBm6M76PHrHao4Q64vSevP1RZpWIc0M",
  height_small: "400px",
  height_medium: "600px",
  markerSVGPath:
    "M 7.8 1.3 L 7.8 1.3 C 6 -0.4 3.1 -0.4 1.3 1.3 c -1.8 1.7 -1.8 4.6 0.6 7.2 l 2.5 4 l 2.8 -3.9 C 9.6 6 9.6 3.2 7.8 1.3 C 7.9 1.4 7.9 1.4 7.8 1.3 z M 4.6 5.8 c -1.2 0 -1.8 -1.1 -1.8 -1.9 c 0 -1.2 1.1 -1.8 1.9 -1.8 c 1.2 0 1.8 1.2 1.8 1.8 C 6.4 5.3 5.3 5.9 4.6 5.8 z",
  openStreetQueryUrl: "https://nominatim.openstreetmap.org/search.php?polygon_geojson=1&format=json&q=",
}
