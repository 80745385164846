import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { InputField, Page } from "@src/components"
import { PARTNER, usePartner } from "@src/partner"
import { putPartnerUuid } from "@src/api"
import { PartnerTabs } from "./tabs"
import { useTranslation } from "react-i18next"

export const PartnerSettingsScreen: React.FC = () => {
  const [loadingField, setLoadingField] = useState<string>("")
  const { partner, updatePartners } = usePartner()
  const [partnerData, setPartnerData] = useState<PARTNER>()
  const { t } = useTranslation()

  const onInputPersist = (field: string) => (text: string) => {
    setLoadingField(field)
    const partnerData = { ...partner, [field]: text }
    putPartnerUuid(partner.uuid, { partner: partnerData }).then(({ response, data }) => {
      if (response.ok) setPartnerData(partnerData)
      updatePartners()
      setLoadingField("")
    })
  }

  useEffect(() => {
    if (partnerData !== partner) {
      setPartnerData(partner)
    }
  }, [partner])

  return (
    <div>
      <Page isSettings showHeader headerValue={t("settings.sidebar.partner")}>
        <div>
          <Col xs={12} md={6} lg={4}>
            <InputField
              value={partnerData && partnerData.name}
              onPersist={onInputPersist("name")}
              loading={loadingField == "name"}
            />
          </Col>
          <div className="mt-4">
            <PartnerTabs />
          </div>
        </div>
      </Page>
    </div>
  )
}
