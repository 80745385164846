import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { AuthenticationProvider } from "./auth"
import { PartnerProvider } from "./partner"
import { LocalizationProvider } from "./localization/useLocalization"
import reportWebVitals from "./reportWebVitals"
import "./index.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { UserProvider } from "./user"
import { FleetProvider } from "./settings"
import { DeviceProvider } from "./device/useDevice"
import { FenceProvider } from "./settings"
import { InviteProvider } from "./invite"

const rootElement = document.getElementById("root")

const root = createRoot(rootElement)
root.render(
  <BrowserRouter>
    <AuthenticationProvider>
      <InviteProvider>
        <PartnerProvider>
          <DeviceProvider>
            <LocalizationProvider>
              <UserProvider>
                <FleetProvider>
                  <FenceProvider>
                    <App />
                  </FenceProvider>
                </FleetProvider>
              </UserProvider>
            </LocalizationProvider>
          </DeviceProvider>
        </PartnerProvider>
      </InviteProvider>
    </AuthenticationProvider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
