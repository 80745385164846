import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Card, Col, Row, Spinner } from "react-bootstrap"
import { Page } from "@src/components"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { getDevice } from "@src/api"
import { useLocalization } from "@src/localization"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import { useFleets } from "@src/settings"
import { colors, config } from "@src/constants"
import {
  PedalBike,
  DirectionsBike,
  Speed,
  Report,
  ModeOfTravel,
  Lock,
  DirectionsRun,
  Co2,
  LocationOn,
} from "@mui/icons-material"
// import {
//   getExpiredServiceBooking,
//   getFleetCo2,
//   getFleetDevicesCount,
//   getFleetDistance,
//   getFleetMovementsCounts,
//   getFleetPositionCounts,
//   getFleetSpeed,
//   getFleetTrip,
//   getFleetTripCounts,
// } from "@src/api/requests/statistics"
import { StatisticCard, DevicesCard } from "./components"
import { centerAlign } from "@src/device/devicedetail/tabs/components"
import "./Dashboard.css"
import { StyleSheet } from "@src/types"
import { SYSTEM_OF_MEASUREMENT, useUser } from "@src/user"
import {
  getCo2,
  getDevicesCount,
  getDevicesWithCriticalBattery,
  getDevicesWithExpiringServicebooking,
  getDevicesWithMovementCount,
  getDevicesWithMovementLasthour,
  getDevicesWithNoServicebooking,
  getDevicesWithTripCount,
  getDevicesWithValidPositionCount,
  getDevicesWithValidPositionLastDay,
  getDistance,
  getExpiredServiceBooking,
  getLockedDevices,
  getLowBatteryDevices,
  getMissingServicebookingDevices,
  getSpeed,
  getTheftsReported,
  getTrips,
  getUnlockedDevices,
} from "@src/api/requests/statistics"
import RadialChart from "./components/RadialChart"

const DAY_IN_MILLISECONDS: number = 24 * 60 * 60 * 1000

type STATISTICS_DATA = {
  devices_count: string
  trip_count: string
  movement_count: string
  movement_lasthour_count: string
  valid_position_count: string
  valid_position_lastday_count: string
  no_servicebooking_count: string
  expiring_servicebooking_count: string
  critical_battery_devices_count: string
  locked_devices_count: string
  unlocked_devices_count: string
  theft_reported_devices_count: string
  avg_distance: string
  total_distance: string
  avg_co2: string
  total_co2: string
  avg_trips: string
  total_trips: string
  avg_speed: string
  total_speed: string
}

const defaultStatisticsData: STATISTICS_DATA = {
  devices_count: "",
  trip_count: "",
  movement_count: "",
  movement_lasthour_count: "",
  valid_position_count: "",
  valid_position_lastday_count: "",
  no_servicebooking_count: "",
  expiring_servicebooking_count: "",
  critical_battery_devices_count: "",
  locked_devices_count: "",
  unlocked_devices_count: "",
  theft_reported_devices_count: "",
  avg_distance: "",
  total_distance: "",
  avg_co2: "",
  total_co2: "",
  avg_trips: "",
  total_trips: "",
  avg_speed: "",
  total_speed: "",
}

const styles: StyleSheet = {
  backgroundDrop: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    height: "100%",
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: ".25rem",
    position: "absolute",
  },
  loaderView: {
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    display: "flex",
    backgroundColor: colors.smokeWhite,
    height: "65px",
    zIndex: 999,
    borderRadius: 8,
    flexDirection: "column",
  },
}

export const DashboardScreen: React.FC = () => {
  const navigate = useNavigate()
  const { formatDate } = useLocalization()
  const { t } = useTranslation()
  const { partner } = usePartner()
  const [loading, setLoading] = useState<boolean>(false)
  const [statisticsData, setStatisticsData] = useState<STATISTICS_DATA>(defaultStatisticsData)
  const [unit, setUnit] = useState<SYSTEM_OF_MEASUREMENT>()
  const { fleet } = useFleets()
  const { user, distanceConversion } = useUser()
  const [expiredDevices, setExpiredDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [missingServiceBookingDevices, setMissingServiceBookingDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [lowBatteryDevices, setLowBatteryDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])

  useEffect(() => {
    if (partner) {
      setLoading(true)
      getStatistics()
      getDevicesListForStatistics()
      // getPartnerExpired()
      fetchDevices()
    }
  }, [partner, fleet])

  useEffect(() => {
    if (user) setUnit(user.system_of_measurement)
  }, [user])

  const fetchDevices = async () => {
    setLoading(true)
    getDevice(partner?.uuid, fleet && fleet.uuid, {
      per_page: config.itemsPerPage,
    }).then(({ response, data }) => {
      if (response.ok) {
        setDevices(data.devices)
        if (!fleet) {
          setLoading(false)
        }
      }
    })
  }

  const getDevicesListForStatistics = async () => {
    const partnerUuid = partner?.uuid
    if (!partnerUuid) {
      return
    }

    try {
      // Use Promise.all to make the API calls concurrently
      const [expiredDevices, missingServiceBookingDevices, lowBatteryDevices] = await Promise.all([
        getExpiredServiceBooking(partnerUuid, fleet?.uuid, {
          partner_id: partner?.uuid,
          fleetUuid: fleet?.uuid,
          page: 0,
          per_page: 20,
          months: 2,
        }),
        getMissingServicebookingDevices(partnerUuid, fleet?.uuid, {
          partner_id: partner?.uuid,
          fleetUuid: fleet?.uuid,
          page: 0,
          per_page: 20,
        }),
        getLowBatteryDevices(partnerUuid, fleet?.uuid, {
          partner_id: partner?.uuid,
          fleetUuid: fleet?.uuid,
          page: 0,
          per_page: 20,
        }),
      ])

      // Process the API results and update state
      setExpiredDevices(expiredDevices?.data?.devices || [])
      setMissingServiceBookingDevices(missingServiceBookingDevices?.data?.devices || [])
      setLowBatteryDevices(lowBatteryDevices?.data?.devices || [])
    } catch (error) {
      console.error("Error fetching devices for statistics:", error)
    } finally {
      // Set loading to false after all operations
      setLoading(false)
    }
  }
  // const getPartnerExpired = async () => {
  //   setLoading(true)
  //   getPartnerExpiredStatistics(partner?.uuid, {
  //     partner_id: partner?.uuid,
  //     page: 0,
  //     per_page: 20,
  //     months: 2,
  //   }).then(({ response, data }) => {
  //     console.log("expired devices", data)
  //     if (response.ok) {
  //       setExpiredDevices(data.devices)
  //     }
  //   })
  // }

  const getStatistics = async () => {
    setLoading(true)
    setStatisticsData(defaultStatisticsData)

    const partnerUuid = partner?.uuid
    if (!partnerUuid) {
      setLoading(false)
      return
    }

    try {
      // Use Promise.all to fire all API calls concurrently
      const [
        devicesCount,
        devicesWithMovementCount,
        devicesWithMovementLastHour,
        devicesWithTripCount,
        devicesWithValidPositionCount,
        devicesWithValidPositionLastDay,
        devicesWithNoServicebooking,
        devicesWithExpiringServicebooking,
        devicesWithCriticalBattery,
        devicesWithLocked,
        devicesWithUnlocked,
        devicesWithTheftReported,
        distance,
        Co2Data,
        tripDetail,
        speed,
      ] = await Promise.all([
        getDevicesCount(partnerUuid, fleet?.uuid),
        getDevicesWithMovementCount(partnerUuid, fleet?.uuid),
        getDevicesWithMovementLasthour(partnerUuid, fleet?.uuid),
        getDevicesWithTripCount(partnerUuid, fleet?.uuid),
        getDevicesWithValidPositionCount(partnerUuid, fleet?.uuid),
        getDevicesWithValidPositionLastDay(partnerUuid, fleet?.uuid),
        getDevicesWithNoServicebooking(partnerUuid, fleet?.uuid),
        getDevicesWithExpiringServicebooking(partnerUuid, fleet?.uuid),
        getDevicesWithCriticalBattery(partnerUuid, fleet?.uuid),
        getLockedDevices(partnerUuid, fleet?.uuid),
        getUnlockedDevices(partnerUuid, fleet?.uuid),
        getTheftsReported(partnerUuid, fleet?.uuid),
        getDistance(partnerUuid, fleet?.uuid),
        getCo2(partnerUuid, fleet?.uuid),
        getTrips(partnerUuid, fleet?.uuid),
        getSpeed(partnerUuid, fleet?.uuid),
      ])

      // Process API responses and set data to state
      defaultStatisticsData.devices_count = `${devicesCount?.data?.count || 0}`
      defaultStatisticsData.trip_count = `${devicesWithTripCount?.data?.count || 0}`
      defaultStatisticsData.movement_count = `${devicesWithMovementCount?.data?.count || 0}`
      defaultStatisticsData.movement_lasthour_count = `${devicesWithMovementLastHour?.data?.count || 0}`
      defaultStatisticsData.valid_position_count = `${devicesWithValidPositionCount?.data?.count || 0}`
      defaultStatisticsData.valid_position_lastday_count = `${devicesWithValidPositionLastDay?.data?.count || 0}`
      defaultStatisticsData.no_servicebooking_count = `${devicesWithNoServicebooking?.data?.count || 0}`
      defaultStatisticsData.expiring_servicebooking_count = `${devicesWithExpiringServicebooking?.data?.count || 0}`
      defaultStatisticsData.critical_battery_devices_count = `${devicesWithCriticalBattery?.data?.count || 0}`
      defaultStatisticsData.locked_devices_count = `${devicesWithLocked?.data?.count || 0}`
      defaultStatisticsData.unlocked_devices_count = `${devicesWithUnlocked?.data?.count || 0}`
      defaultStatisticsData.theft_reported_devices_count = `${devicesWithTheftReported?.data?.count || 0}`
      defaultStatisticsData.avg_distance = `${distanceConversion(distance?.data?.avg_distance || 0)}`
      defaultStatisticsData.total_distance = `${distanceConversion(distance?.data?.total_distance || 0)}`
      defaultStatisticsData.avg_co2 = `${Math.round(Co2Data?.data?.avg_co2 || 0)} ${Co2Data?.data?.avg_co2_unit || ""}`
      defaultStatisticsData.total_co2 = `${Math.round(Co2Data?.data?.total_co2 || 0)} ${
        Co2Data?.data?.total_co2_unit || ""
      }`
      defaultStatisticsData.avg_trips = `${tripDetail?.data?.avg_trips || 0}`
      defaultStatisticsData.total_trips = `${tripDetail?.data?.total_trips || 0}`
      defaultStatisticsData.avg_speed = `${speed?.data?.avg_speed || 0} ${speed?.data?.avg_speed_unit || ""}`
      defaultStatisticsData.total_speed = `${speed?.data?.total_speed || 0} ${speed?.data?.total_speed_unit || ""}`

      setStatisticsData(defaultStatisticsData)
    } catch (error) {
      console.error("Error fetching statistics:", error)
    } finally {
      setLoading(false)
    }
  }

  const onDeviceClick = (device: SLIM_DEVICE_TYPE) => {
    navigate(`/${partner?.uuid}/device/${device.serialnumber}`)
  }

  const renderLoaderSpinner = () => {
    return (
      <>
        <div className={centerAlign} style={styles.backgroundDrop}>
          <div className={centerAlign} style={styles.loaderView}>
            <Spinner className="align-self-center" animation="border" size="sm" />
            <b className="m-2">{t("dashboard_screen.loader")}</b>
          </div>
        </div>
      </>
    )
  }

  const runningOutServiceBookingDevices = devices.filter(
    (device) =>
      device.servicebooking !== null &&
      new Date(device.servicebooking.service_end_date).getTime() - new Date().getTime() <= 60 * DAY_IN_MILLISECONDS,
  )

  return (
    <Page
      showFleet
      showHeader
      isRelative
      headerValue={t("sidebar.statistics")}
      onSelectFleet={() => {
        setLoading(true)
        getDevicesListForStatistics()
        // setDevices([])
      }}>
      {loading && renderLoaderSpinner()}
      <Row>
        <StatisticCard
          show={true}
          title={t("dashboard_screen.devices_activated")}
          totalCount={statisticsData.devices_count}
          loading={loading}
          isDeviceActive={true}
          tooltip={t("dashboard_screen.tooltip.devices_activated")}
          onButtonClick={() => navigate(`/${partner?.uuid}/settings/partner?tab=add`)}
        />
        <StatisticCard
          show={true}
          title={t("dashboard_screen.tracked_devices")}
          tooltip={t("dashboard_screen.tooltip.tracked_devices")}
          totalCount={statisticsData.valid_position_count}
          loading={loading}
        />
        <StatisticCard
          show={true}
          title={t("dashboard_screen.24h_active_devices")}
          tooltip={t("dashboard_screen.tooltip.24h_active_devices")}
          totalCount={statisticsData.valid_position_lastday_count}
          loading={loading}
        />
        <StatisticCard
          show={true}
          title={t("dashboard_screen.total_movements")}
          tooltip={t("dashboard_screen.tooltip.total_movements")}
          totalCount={statisticsData.movement_count}
          loading={loading}
        />
      </Row>

      <Row>
        <StatisticCard
          show={true}
          title={t("dashboard_screen.critical_battery")}
          isRadial={true}
          totalRadialValue={statisticsData.devices_count}
          radialValue={statisticsData.critical_battery_devices_count}
          tooltip={t("dashboard_screen.tooltip.critical_battery")}
          loading={loading}
          onButtonClick={() => navigate(`/${partner?.uuid}/settings/partner?tab=add`)}
        />
        <StatisticCard
          show={true}
          isColumnDetail={true}
          title={t("dashboard_screen.devices_status")}
          isDetailed={true}
          tooltip={t("dashboard_screen.tooltip.devices_status")}
          titles={["Locked", "Unlocked"]}
          detailCounts={[statisticsData.locked_devices_count, statisticsData.unlocked_devices_count]}
          loading={loading}
        />
        <Col>
          <StatisticCard
            show={true}
            title={t("dashboard_screen.total_rides")}
            totalCount={statisticsData.trip_count}
            tooltip={t("dashboard_screen.tooltip.total_rides")}
            loading={loading}
          />
          <StatisticCard
            show={true}
            title={t("dashboard_screen.recent_activity_1h")}
            totalCount={statisticsData.movement_count}
            tooltip={t("dashboard_screen.tooltip.recent_activity_1h")}
            loading={loading}
          />
        </Col>
      </Row>
      <Row>
        <StatisticCard
          show={true}
          title={t("dashboard_screen.devices_trip")}
          isDetailed={true}
          titles={[t("dashboard_screen.average_trips"), t("dashboard_screen.total_trips")]}
          tooltip={t("dashboard_screen.tooltip.devices_trip")}
          detailCounts={[statisticsData.avg_trips, statisticsData.total_trips]}
          loading={loading}
        />
        <StatisticCard
          show={true}
          title={t("dashboard_screen.devices_speed")}
          isDetailed={true}
          titles={[t("dashboard_screen.average_speed")]}
          tooltip={t("dashboard_screen.tooltip.devices_speed")}
          detailCounts={[statisticsData.avg_speed]}
          loading={loading}
        />
      </Row>
      <Row>
        <StatisticCard
          show={true}
          title={t("dashboard_screen.co2_savings")}
          isDetailed={true}
          titles={[t("dashboard_screen.average_co2"), t("dashboard_screen.total_co2")]}
          tooltip={t("dashboard_screen.tooltip.co2_savings")}
          detailCounts={[statisticsData.avg_co2, statisticsData.total_co2]}
          loading={loading}
        />
        <StatisticCard
          show={true}
          title={t("dashboard_screen.devices_distance")}
          isDetailed={true}
          titles={[t("dashboard_screen.average_distance"), t("dashboard_screen.total_distance")]}
          tooltip={t("dashboard_screen.tooltip.devices_distance")}
          detailCounts={[statisticsData.avg_distance, statisticsData.total_distance]}
          loading={loading}
        />
      </Row>
      <Row>
        <StatisticCard
          show={true}
          title={t("dashboard_screen.reported_thefts")}
          tooltip={t("dashboard_screen.tooltip.reported_thefts")}
          totalCount={statisticsData.theft_reported_devices_count}
          loading={loading}
        />

        <StatisticCard
          show={true}
          title={t("dashboard_screen.pending_service")}
          totalCount={statisticsData.no_servicebooking_count}
          tooltip={t("dashboard_screen.tooltip.pending_service")}
          loading={loading}
        />
        <StatisticCard
          show={true}
          title={t("dashboard_screen.due_for_service")}
          totalCount={statisticsData.expiring_servicebooking_count}
          tooltip={t("dashboard_screen.tooltip.due_for_service")}
          loading={loading}
        />
      </Row>

      <Row className="mb-3 mt-2">
        <DevicesCard
          title={t("dashboard_screen.devices_with_critical_battery")}
          devices={lowBatteryDevices}
          loading={loading}
          tooltip={t("dashboard_screen.tooltip.critical_battery_devices")}
          onDeviceClick={onDeviceClick}
          formatDeviceTitle={(device) => `${device.name} - ${device.battery} %`}
        />
        <DevicesCard
          title={t("dashboard_screen.devices_withouth_servicebooking")}
          devices={missingServiceBookingDevices}
          loading={loading}
          tooltip={t("dashboard_screen.tooltip.devices_without_service")}
          onDeviceClick={onDeviceClick}
          formatDeviceTitle={(device) => `${device.serialnumber} - ${device.name}`}
        />
      </Row>
      <Row>
        {/* <DevicesCard
          title={t("dashboard_screen.devices_with_ending_servicebookings", { value: "60" })}
          devices={expiredDevices}
          loading={loading}
          showDetail={true}
          tooltip={t("dashboard_screen.tooltip.devices_with_ending_service")}
          onDetailClick={(devices) => {
            navigate(`/${partner?.uuid}/devicesexpiringsoon`, {
              state: { devices },
            })
          }}
          onDeviceClick={onDeviceClick}
          formatDeviceTitle={(device) =>
            `${device.name}  ${
              device?.servicebooking ? "- " + formatDate(new Date(device?.servicebooking?.service_end_date)) : ""
            }`
          }
        /> */}

        <DevicesCard
          title={t("dashboard_screen.devices_with_ending_servicebookings", { value: "60" })}
          devices={runningOutServiceBookingDevices}
          loading={loading}
          showDetail={true}
          onDetailClick={(devices) => {
            navigate(`/${partner?.uuid}/devicesexpiringsoon`, {
              state: { devices },
            })
          }}
          onDeviceClick={onDeviceClick}
          formatDeviceTitle={(device) =>
            `${device.name}  ${
              device?.servicebooking ? "- " + formatDate(new Date(device?.servicebooking?.service_end_date)) : ""
            }`
          }
        />
      </Row>
    </Page>
  )
}
