import React, { useState } from "react"
import { Col, Row, Form, Button, Spinner } from "react-bootstrap"
import { InputField } from "@src/components"
import { PartnerDetail } from "@src/settings/partner/tabs/PartnerDetail"
import { CREATE_PARTNER, postPartner } from "@src/api"
import { useTranslation } from "react-i18next"

type CreatePartnerProps = {
  onPartnerCreated: () => void
  onCancel: () => void
}

export const CreatePartner: React.FC<CreatePartnerProps> = ({ onPartnerCreated, onCancel }) => {
  const [partnerData, setPartnerData] = useState<CREATE_PARTNER>({ color: "000000" })
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const onInputPersist = (field: string) => (text: string) => {
    const partnerDataFill = { ...partnerData, [field]: text }
    setPartnerData(partnerDataFill)
  }

  const createPartner = async () => {
    setLoading(true)
    const createPartnerResponse = await postPartner({ partner: partnerData })
    if (createPartnerResponse.data) {
      setLoading(false)
      onPartnerCreated()
    }
  }

  return (
    <div className="m-3">
      <Form.Label className="fw-bold align-self-center mt-2 mb-2">
        {t("select_partner_screen.create_partner").toUpperCase()}
      </Form.Label>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <Row className="mt-3">
            <div className="d-flex">
              <b className="small">{t("select_partner_screen.name")}</b>
            </div>
            <Col>
              <InputField value={""} onPersist={onInputPersist("name")} loading={false} />
            </Col>
          </Row>

          <PartnerDetail
            isCreatePartner={true}
            onColorChange={(color) => {
              const partnerDataFill = { ...partnerData, color: color.replace("#", "") }
              setPartnerData(partnerDataFill)
            }}
            onLogoChange={(logoUrl) => {
              const partnerDataFill = { ...partnerData, logo_url: logoUrl }
              setPartnerData(partnerDataFill)
            }}
          />
          <div className="d-flex flex-row-reverse">
            <div>
              <Button
                className="btn btn-primary mt-2 me-2"
                disabled={!partnerData?.name || !partnerData?.color || !partnerData?.logo_url}
                onClick={() => {
                  createPartner()
                }}>
                {t("select_partner_screen.create")}
              </Button>
              <Button
                className="btn btn-primary-outline mt-2"
                onClick={() => {
                  onCancel()
                }}>
                {t("settings.fleets.cancel")}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
