import { callApi } from "@src/api/callApi"

type POST_FLEET_SETTINGS_BODY = {
  battery_low?: boolean
  stolen?: boolean
  locked?: boolean
  custom?: boolean
  locked_movement?: boolean
  power_switched_on?: boolean
  power_switched_off?: boolean
  locked_distance?: false
  unlocking_inactive_user?: boolean
  left_geofence?: boolean
  crash?: boolean
  left_service_area?: boolean
  sending_problem_detected?: boolean
  battery_very_low?: boolean
  trip_begin?: boolean
  user_added?: boolean
  entered_geofence?: boolean
  emergency_assistant_available?: boolean
  emergency_assistant_not_available?: boolean
  bike_telemetry?: boolean
  service_expiring?: boolean
}

export const postFleetsUuidSettings = (partnerUuid: string, fleetUuid: string, body: POST_FLEET_SETTINGS_BODY) =>
  callApi<POST_FLEET_SETTINGS_BODY, null>({
    method: "POST",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/settings`,
    body,
  })
