import detector from "i18next-browser-languagedetector"
import i18next, { Module } from "i18next"
import { initReactI18next } from "react-i18next"
import { translations } from "./translations/translations"
import { localization } from "@src/config"

const transformTranslation = () => {
  const resources = {}
  for (const lng of localization.languages) {
    resources[lng] = { translation: translations[lng] }
  }
  return resources
}

i18next.use(initReactI18next as Module)
i18next.use(detector)
i18next.init({
  interpolation: {
    escapeValue: false,
  },
  debug: true,
  lng: localization.defaultLang,
  preload: localization.languages,
  supportedLngs: localization.languages,
  fallbackLng: localization.defaultLang,
  resources: transformTranslation(),
})

export default i18next
