import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { InputField, Page } from "@src/components"
import { usePartner } from "@src/partner"
import { SYSTEM_OF_MEASUREMENT, USER, useUser } from "@src/user"
import { Col, Dropdown, DropdownButton, Spinner } from "react-bootstrap"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"

export const UserSettingsScreen: React.FC = () => {
  const { t } = useTranslation()
  const { user, updateUser } = useUser()
  const [loadingField, setLoadingField] = useState<string>("")
  const [userData, setUserData] = useState<USER>(user)
  const [systemOfMeasurement, setSystemOfMeasurement] = useState<SYSTEM_OF_MEASUREMENT>()
  const units = [SYSTEM_OF_MEASUREMENT.METRIC, SYSTEM_OF_MEASUREMENT.IMPERIAL]
  const [loading, setLoading] = useState<boolean>(false)

  const onInputPersist = (field: string) => (text: string) => {
    setLoadingField(field)
    const userRequest = { ...userData, [field]: text }
    setUserData(userRequest)
    updateUser(userRequest)
    setTimeout(() => {
      setLoadingField("")
    }, 100)
  }

  useEffect(() => {
    setLoading(false)
    setUserData(user)
    setSystemOfMeasurement(user && user.system_of_measurement)
  }, [user])

  return (
    <Page isSettings showHeader headerValue={t("settings.sidebar.user")}>
      <>
        <Col xs={12} md={6} lg={4}>
          <InputField
            label={t("settings.user.first_name")}
            value={user && user.firstname}
            onPersist={onInputPersist("firstname")}
            loading={loadingField == "firstname"}
          />
          <InputField
            label={t("settings.user.last_name")}
            value={user && user.lastname}
            onPersist={onInputPersist("lastname")}
            loading={loadingField == "lastname"}
          />
          <>
            <b className="small">{t("settings.user.unit")}:</b>
            <div className="d-flex flex-row align-items-center">
              <DropdownButton
                className="mt-2 me-2"
                id="time-interval-picker"
                title={
                  systemOfMeasurement ? t(`settings.user.${systemOfMeasurement}`) : t("settings.user.select_unit")
                }>
                {units.map((item, i) => (
                  <Dropdown.Item
                    key={item}
                    eventKey="1"
                    onClick={() => {
                      setLoading(true)
                      const userRequest = { ...userData, system_of_measurement: item }
                      updateUser(userRequest)
                      setSystemOfMeasurement(item)
                    }}>
                    {t(`settings.user.${item}`)}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {loading && <Spinner style={sortArrowStyles.loader} animation="border" className="mt-2" />}
            </div>
          </>
        </Col>
      </>
    </Page>
  )
}
