import { colors } from "@src/constants"
import React from "react"
import { Form, Row } from "react-bootstrap"
import * as Icon from "react-bootstrap-icons"

type BatteryProps = {
  battery_value?: number
  charging?: boolean
}

export const Battery: React.FC<BatteryProps> = ({ battery_value, charging }) => {
  const colorFills = battery_value <= 10 ? colors.red : battery_value <= 25 ? colors.mustardYellow : colors.darkGray

  const BatteryIcons = () => {
    if (charging) {
      return <Icon.BatteryCharging color={colors.green} size={20} />
    } else {
      switch (true) {
        case battery_value < 25:
          return <Icon.Battery color={colorFills} size={23} />
        case battery_value >= 25 && battery_value < 75:
          return <Icon.BatteryHalf color={colorFills} size={23} />
        case battery_value >= 75 && battery_value <= 100:
          return <Icon.BatteryFull color={colorFills} size={23} />
        default:
          break
      }
    }
  }

  return (
    <Row>
      <div className="d-flex align-items-center ms-3">
        <BatteryIcons />
        <Form.Text className="ms-2 mb-1" muted>
          {battery_value}%
        </Form.Text>
      </div>
    </Row>
  )
}
