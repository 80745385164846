import { callApi } from "../callApi"

type DELETE_TOKEN_BODY = {
  email: string
  password: string
}

export type DELETE_TOKEN_RESPONSE = {
  user_token: {
    access_token: string
    user: {
      id: number
      email: string
      firstname: string
      lastname: string
      phone: string
      address: string
    }
  }
}

export const deleteToken = async () =>
  callApi<{ user: DELETE_TOKEN_BODY }, DELETE_TOKEN_RESPONSE>({
    method: "DELETE",
    path: "/token",
  })
