import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Form, Dropdown, DropdownButton, Spinner, Card } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, ChartOptions } from "chart.js"
import { Bar } from "react-chartjs-2"
import { getDeviceSerialnumberStatisticsWithGraph } from "@src/api/requests"
import { usePartner } from "@src/partner"
import { ATTRIBUTE, PERIOD } from "@src/api/types"
import { sToH } from "@src/utils"
import { colors } from "@src/constants"
import { useLocalization } from "@src/localization"
import { PermissionCard } from "@src/components"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { localization } from "@src/config"
import dayjs from "dayjs"
import { useUser } from "@src/user"

ChartJS.register(CategoryScale, BarElement, LinearScale, Tooltip)

const periodLabels = ["week", "month", "year"]

const atrributeToUnit = {
  distance: "km",
  riding_time: "h",
  avg_speed: "km/h",
  co2saving: "kg",
}
const attributeLabels = Object.keys(atrributeToUnit)

const calcMatrix = {
  distance: {
    week: (value) => Math.floor(value),
    month: (value) => Math.floor(value),
    year: (value) => Math.floor(value),
  },
  riding_time: {
    week: sToH,
    month: sToH,
    year: sToH,
  },
  avg_speed: {
    week: (value) => Math.floor(value),
    month: (value) => Math.floor(value),
    year: (value) => Math.floor(value),
  },
  co2saving: {
    week: (value) => Math.floor(value),
    month: (value) => Math.floor(value),
    year: (value) => Math.floor(value),
  },
}

type StatisticsTabProps = {
  serialnumber: string
}

export const StatisticsTab: React.FC<StatisticsTabProps> = ({ serialnumber }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const { brand, partner } = usePartner()
  const [chartData, setChartData] = useState<{ labels: string[]; values: string[]; units: string[] }>({
    labels: [],
    values: [],
    units: [],
  })
  const [avgStatisticsValue, setAvgStatisticsValue] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const { language, formatDate, region, isValidFormat } = useLocalization()
  const [chartLabels, setChartLabels] = useState([])
  const [showToast, setShowToast] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("")
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [changeDate, setChangeDate] = useState<Boolean>(false)
  const { user, distanceConversion } = useUser()

  const tab = searchParams.get("tab")
  const period = searchParams.get("period") as PERIOD
  const attribute = searchParams.get("attribute") as ATTRIBUTE
  const date = searchParams.get("date")

  const styles = {
    datepickerDiv: {
      borderWidth: 0.8,
      borderStyle: "solid",
      borderColor: "#dee2e6",
      borderRadius: "4px",
    },
    warningCard: {
      backgroundColor: colors.whiteGray,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      alignSelf: "center",
    },
  }

  const updateSearchParams = (param: string, value: string) => {
    searchParams.set(param, value)
    setSearchParams(searchParams, { replace: true })
  }

  useEffect(() => {
    if (tab == "statistics") {
      attribute && period && date && updateStatisticGraph()
      !attributeLabels.includes(attribute) && updateSearchParams("attribute", "distance")
      !periodLabels.includes(period) && updateSearchParams("period", "week")
      const todaysDate = new Date().toISOString().split("T")[0]
      !date && updateSearchParams("date", formatDate(new Date(todaysDate)).replace(/\./g, "-"))
    }
  }, [tab, attribute, period])

  useEffect(() => {
    if (changeDate) {
      updateStatisticGraph()
    }
  }, [changeDate])

  const updateStatisticGraph = () => {
    setLoading(true)
    setChangeDate(false)
    let converted_date = dayjs(selectedDate).format("YYYY-MM-DD")
    getDeviceSerialnumberStatisticsWithGraph(partner?.uuid, serialnumber, {
      period,
      start_date: converted_date,
      attribute: attribute,
    }).then(({ response, data }) => {
      if (response.ok) {
        const labels = []
        const values = []
        const units = []
        data?.statistics?.graph_data.map((item) => {
          labels.push(Object.keys(item)[0])
          values.push(
            attribute == "distance"
              ? distanceConversion(Object.values(item)[0] as number).split(" ")[0]
              : calcMatrix[attribute][period](Object.values(item)[0]),
          )
          if (attribute == "distance") {
            units.push(user.system_of_measurement == "metric" ? "km" : "mi")
          } else {
            units.push(data.statistics[`${attribute}_unit`])
          }
        })
        if (converted_date !== data.meta.start_date) {
          setToastText(t("device_screen.statistic_tab.data_till_date", { value: data.meta.start_date }))
          setShowToast(true)
        } else {
          setShowToast(false)
        }

        const metaDate = data.meta.start_date.split(" ")[0]
        let converted_meta_date = new Date(dayjs(metaDate).format("YYYY-MM-DD"))
        if (selectedDate !== converted_meta_date) {
          setSelectedDate(converted_meta_date)
        }
        setChartLabels(labels)
        setChartData({ labels, values, units })
        const avgValue = data.statistics[attribute]
        setAvgStatisticsValue(
          attribute == "distance"
            ? distanceConversion(avgValue)
            : attribute == "riding_time"
              ? `${calcMatrix[attribute][period](avgValue)}h`
              : `${calcMatrix[attribute][period](avgValue)} ${data.statistics[`${attribute}_unit`]}`,
        )
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (period !== "month") {
      const labels = []
      const values = chartData.values
      const units = chartData.units
      chartLabels.map((item) => {
        labels.push(t(`device_screen.statistic_tab.${item}`))
      })
      setChartData({ labels, values, units })
    }
  }, [language, chartLabels])

  const data = {
    type: "bar",
    labels: chartData.labels,
    datasets: [
      {
        label: chartData.units[0],
        data: chartData.values,
        backgroundColor: brand.color,
        borderWidth: 0,
        borderRadius: 25,
        borderColor: colors.white,
        innerWidth: 12,
        outerWidth: 10,
        barThickness: 10,
        fill: false,
        minBarLength: 2,
      },
    ],
  }

  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const onChangeAttribute = (newAttribute) => {
    updateSearchParams("attribute", newAttribute)
  }

  const onChangePeriod = (newPeriod) => {
    updateSearchParams("period", newPeriod)
  }

  const onChangeDate = (selectedDate) => {
    setSelectedDate(selectedDate)
    var dateFormatted = formatDate(selectedDate).replace(/\./g, "-")
    updateSearchParams("date", dateFormatted)
    setChangeDate(true)
  }

  const ChartJS = useCallback(() => <Bar options={options} data={data} width={"100%"} height={50} />, [chartData])
  const avgText = useMemo(
    () => attribute && t(`device_screen.statistic_tab.${attribute}_sentence`, { value: avgStatisticsValue }),
    [attribute, avgStatisticsValue, language],
  )

  if (!partner?.permissions?.can_see_device_statistics) return <PermissionCard />

  if (!(tab == "statistics" && period && attribute && data)) return null

  return (
    <div>
      <div className="d-flex justify-content-end mb-2 align-items-center">
        {loading && <Spinner size="sm" animation="border" className="me-2" />}
        <DropdownButton title={t(`device_screen.statistic_tab.${attribute}`)} variant="" onSelect={onChangeAttribute}>
          {attributeLabels.map((attributeLabel, i) => (
            <Dropdown.Item as="label" key={i} eventKey={attributeLabel} active={attributeLabel == attribute}>
              {t(`device_screen.statistic_tab.${attributeLabel}`)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <DropdownButton
          title={t(`device_screen.statistic_tab.${period}`)}
          className="me-2"
          variant=""
          onSelect={onChangePeriod}>
          {periodLabels.map((periodLabel, idx) => (
            <Dropdown.Item as="label" key={idx} eventKey={periodLabel} active={periodLabel == period}>
              {t(`device_screen.statistic_tab.${periodLabel}`)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <div style={styles.datepickerDiv}>
          <DatePicker
            wrapperClassName="datePicker"
            selected={selectedDate}
            onChange={onChangeDate}
            dateFormat={localization.localizationFormat[region][3]}
            closeOnScroll
            showIcon
          />
        </div>
      </div>

      <ChartJS />
      <Form.Text muted className="d-flex justify-content-center">
        {avgText}
      </Form.Text>
      {showToast && (
        <div className="d-flex justify-content-center">
          <Card style={styles.warningCard} className="mt-3 mb-4 p-2">
            {toastText}
          </Card>
        </div>
      )}
    </div>
  )
}
