import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { usePartner } from "@src/partner"
import { useFleets } from "@src/settings"
import React from "react"
import { Button, Card, CardTitle, Col, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import RadialChart from "./RadialChart"
import { StyleSheet } from "@src/types"
import { InfoCircle } from "react-bootstrap-icons"

type StatisticCardProps = {
  show: boolean
  title: string
  loading: boolean
  isDetailed?: boolean
  isDeviceActive?: boolean
  totalCount?: any
  isRadial?: boolean
  isColumnDetail?: boolean
  totalRadialValue?: string
  radialValue?: string
  tooltip: string
  titles?: string[]
  detailCounts?: any[]
  onButtonClick?: () => void
}

const pageClassName = "shadow-2 rounded mt-2 mb-2"
export const alignCenterBetween = "d-flex flex-row align-items-center justify-content-between"

export const StatisticCard: React.FC<StatisticCardProps> = ({
  show,
  title,
  loading,
  isRadial,
  totalRadialValue,
  radialValue,
  isColumnDetail,
  isDetailed,
  isDeviceActive,
  totalCount,
  tooltip,
  titles = [],
  detailCounts = [],
  onButtonClick,
}) => {
  const { brand } = usePartner()
  const { t } = useTranslation()
  const styles: StyleSheet = {
    borderStyles: {
      flex: 1,
      borderWidth: 3,
      borderColor: "#F0F0F0",
      borderRadius: "30px",
      height: "inherit",
    },
    cardTitle: {
      color: "#636E72",
    },
    cardValue: {
      color: brand?.color,
    },
    detail: {
      flex: 1,
      flexDirection: "column",
      marginTop: "10px",
      marginRight: "10px",
      padding: "10px",
      backgroundColor: "#F0F0F0",
      justifyContent: "space-between",
      borderRadius: "10px",
    },
    divider: {
      height: "70px",
      backgroundColor: "green",
      width: 2,
    },
    horizontalDivider: {
      backgroundColor: "#F0F0F0",
      width: "100%",
      height: 2,
    },
  }

  const Link = ({ id, children }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{tooltip || "No tooltip available"}</Tooltip>}>
      <a href="#" className="ms-1 me-1">
        {children}
      </a>
    </OverlayTrigger>
  )

  if (show) {
    return (
      <Col style={{ display: "flex" }}>
        <Card className={pageClassName} style={styles.borderStyles}>
          <Card.Body>
            {!isDetailed ? (
              <>
                <div className="d-flex flex-row">
                  <Card.Title className="fs-5" style={styles.cardTitle}>
                    {title}
                  </Card.Title>
                  <div className="ps-1 pe-1" style={{ width: "35px" }}>
                    <Link id="info">
                      <InfoCircle color="#636E72" size={15} />
                    </Link>
                  </div>
                </div>
                {loading ? (
                  <Spinner style={sortArrowStyles.loader} animation="border" />
                ) : (
                  <>
                    {isDeviceActive && totalCount == 0 && !loading ? (
                      <div className="d-flex flex-column">
                        <label>{t("settings.partner.no_trackers")}</label>
                        <Button className="mt-2 align-self-end" onClick={onButtonClick}>
                          {t("settings.partner.add_tracker")}
                        </Button>
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        {isRadial && totalRadialValue && radialValue ? (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "10px",
                              marginTop: "20px",
                            }}>
                            <RadialChart
                              totalValue={parseInt(totalRadialValue)}
                              value={parseInt(radialValue)}
                              size={100}
                              strokeWidth={10}
                            />
                          </div>
                        ) : (
                          <b className="fs-1" style={styles.cardValue}>
                            {totalCount || "N/A"}
                          </b>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div className={alignCenterBetween}>
                  <div className="d-flex flex-row mt-1">
                    <Card.Title className="fs-5" style={styles.cardTitle}>
                      {title}
                    </Card.Title>
                    <div className="ps-1 pe-1" style={{ width: "35px" }}>
                      <Link id="info">
                        <InfoCircle color="#636E72" size={15} />
                      </Link>
                    </div>
                  </div>
                  {totalCount && (
                    <div className="align-self-start me-3">
                      <div className="fs-1" style={styles.cardValue}>
                        {totalCount}
                      </div>
                    </div>
                  )}
                </div>
                {loading ? (
                  <Spinner style={sortArrowStyles.loader} animation="border" />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: isColumnDetail ? "column" : "row",
                    }}>
                    {titles.map(
                      (title, index) =>
                        title &&
                        detailCounts[index] !== undefined && (
                          <div key={index} style={styles.detail}>
                            <div style={styles.cardValue}>{title}</div>
                            <div>
                              <b className="fs-3" style={styles.cardValue}>
                                {detailCounts[index]}
                              </b>
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    )
  }
}
