import * as Sentry from "@sentry/react"

type SENTRY_DATA_OBJECT = {
  method: string
  userText: string
  body: string
  url: string
  status: number
  statusText: string
}

export const putSentryLog = (logData: SENTRY_DATA_OBJECT) => {
  Sentry.setContext("API Information", logData)
  Sentry.captureException(new Error("API Logs: something went wrong"))
}
