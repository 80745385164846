import React, { useEffect, useState } from "react"
import { Tabs, Tab } from "react-bootstrap"
import { DEVICE_TYPE } from "@src/devices/types"
import { DetailsTab } from "./DetailsTab"
import { StatisticsTab } from "./StatisticsTab"
import { useTranslation } from "react-i18next"
import { RouteTab } from "./routetab/RouteTab"
import { useSearchParams } from "react-router-dom"
import { usePartner } from "@src/partner"

// TODO: string file will be added in the separate ticket task.
const TABS = ["details", "statistics", "route"]

type DeviceTabsProps = {
  serialnumber: string
}

export const DeviceTabs: React.FC<DeviceTabsProps> = ({ serialnumber }) => {
  const { t } = useTranslation()
  const { partner } = usePartner()
  const [searchParams, setSearchParams] = useSearchParams()
  const [key, setKey] = useState<string>("details")
  const tab = searchParams.get("tab")

  useEffect(() => {
    setKey("details")
    if (!TABS.includes(tab)) setSearchParams({ tab: "details" }, { replace: true })
  }, [serialnumber])

  useEffect(() => {
    if (tab) setKey(tab)
  }, [tab])

  return (
    <Tabs
      activeKey={tab}
      onSelect={(key) => {
        setKey(key)
        setSearchParams({ tab: key })
      }}
      className="mb-3">
      <Tab eventKey={TABS[0]} title={t("device_screen.details")}>
        {key == "details" && <DetailsTab serialnumber={serialnumber} />}
      </Tab>
      <Tab eventKey={TABS[1]} title={t("device_screen.driving_statistics")}>
        <StatisticsTab serialnumber={serialnumber} />
      </Tab>
      <Tab eventKey={TABS[2]} title={t("device_screen.routes")}>
        {key == "route" && <RouteTab serialnumber={serialnumber} />}
      </Tab>
    </Tabs>
  )
}
