import React from "react"
import * as Icon from "react-bootstrap-icons"

type SortArrowIconProps = {
  loading: boolean
  arrowUp: boolean
}

export const sortArrowStyles = {
  sortArrowIconButton: {
    width: "14px",
  },
  loader: {
    border: "2px solid #111",
    borderTop: "2px solid rgb(240, 240, 240)",
    borderRadius: "70%",
    width: "14px",
    height: "14px",
    animation: "App-logo-spin 0.5s linear infinite",
  },
}

export const SortArrowIcon: React.FC<SortArrowIconProps> = ({ loading, arrowUp }) => {
  if (loading) return <div className="ms-2" style={sortArrowStyles.loader} />
  else if (arrowUp)
    return (
      <div style={sortArrowStyles.sortArrowIconButton}>
        <Icon.CaretUpFill className="ms-2" size={10} />
      </div>
    )
  return (
    <div style={sortArrowStyles.sortArrowIconButton}>
      <Icon.CaretDownFill className="ms-2" size={10} />
    </div>
  )
}
