import { callApi } from "../callApi"
import { ATTRIBUTE, PERIOD, TRIP } from "../types"

export type GET_DEVICE_SERIALNUMBER_STASTICS_WITH_GRAPH_PARAMS = {
  attribute?: ATTRIBUTE | string
  period?: PERIOD
  start_date?: string
  page?: number
  per_page?: number
  search_string?: string
  sort_by?: string
  sort_desc?: boolean
}

type GET_DEVICE_SERIALNUMBER_STASTICS_WITH_GRAPH_RESPONSE = {
  statistics: {
    graph_data: []
    avg_speed_kmh: number
    distance: number
    avg_speed: number
    riding_time: number
    co2saving: number
    trips: TRIP[]
    avg_speed_unit: string
    distance_unit: string
    co2saving_unit: string
  }
  meta: {
    page: number
    per_page: number
    total: number
    sort_by: string
    sort_desc: string
    period: PERIOD
    start_date: string
    start_period: string
    end_period: string
    prev_date: string
    next_date: string
  }
}

export const getDeviceSerialnumberStatisticsWithGraph = (
  partnerUuid: string,
  serialnumber: string,
  params: GET_DEVICE_SERIALNUMBER_STASTICS_WITH_GRAPH_PARAMS,
) => {
  const queryString = Object.keys(params || {})
    .map((key) => key + "=" + params[key])
    .join("&")
  return callApi<null, GET_DEVICE_SERIALNUMBER_STASTICS_WITH_GRAPH_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/device/${serialnumber}/ride_statistics?` + queryString,
  })
}
