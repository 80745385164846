import React, { useEffect, useState } from "react"
import { putDeviceSerialnumberUnlock, putDeviceSerialnumberLock, getDeviceSerialnumber } from "@src/api/requests"
import { Spinner, Button } from "react-bootstrap"
import { LockUnlockIcon } from "@src/components"
import { usePartner } from "@src/partner"
import { useDevice } from "@src/device/useDevice"
import { postiLockitLockStatus } from "@src/api/ilockit"
import { DEVICE_TYPE } from "@src/devices/types"

type LockUnlockButtonProps = {
  serialnumber: string
  showLockingTimedoutAlert: React.Dispatch<React.SetStateAction<boolean>>
}

export const LockUnlockButton: React.FC<LockUnlockButtonProps> = ({ serialnumber, showLockingTimedoutAlert }) => {
  const { device, loadDevice } = useDevice()
  const { partner } = usePartner()
  const [loading, setLoading] = useState<boolean>(false)
  const [lockStatus, setLockStatus] = useState<DEVICE_TYPE["locked"]>(device?.locked)
  const isiLockit = !!device?.ilockitCredentials
  useEffect(() => {
    setLoading(false)
  }, [device])
  const onLockUnlock = async () => {
    setLoading(true)

    if (isiLockit) {
      const response = await postiLockitLockStatus(
        { partnerUuid: partner?.uuid, serialnumber },
        { open: lockStatus === "locked" },
      )
      if (response?.response?.ok) {
        const isStatusChanged = await waitForILockItLockStatusChange()
        isStatusChanged && setLockStatus(lockStatus === "locked" ? "open" : "locked")
        !isStatusChanged && showLockingTimedoutAlert(true)
      }
      return setLoading(false)
    } else {
      if (lockStatus === "locked") {
        await putDeviceSerialnumberUnlock(partner?.uuid, serialnumber)
      } else {
        await putDeviceSerialnumberLock(partner?.uuid, serialnumber)
      }
      loadDevice(serialnumber, null)
    }
  }

  const waitForILockItLockStatusChange = async (maxRetries = 15, retryInterval = 1000) => {
    for (let i = 0; i < maxRetries; i++) {
      const { response, data } = await getDeviceSerialnumber(partner?.uuid, serialnumber)
      if (response.ok && data.locked !== device.locked) {
        console.log("Lock status changed")
        return true
      }
      console.log(`Retrying in ${retryInterval / 1000} seconds...`)
      await new Promise((resolve) => setTimeout(resolve, retryInterval))
    }
  }
  return (
    <div>
      <Button variant={"light"} onClick={onLockUnlock} disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : <LockUnlockIcon locked={lockStatus} />}
      </Button>
    </div>
  )
}
