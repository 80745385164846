import { callApi } from "@src/api/callApi"
import { DEVICE_TYPE } from "@src/devices/types"

export type PUT_DEVICE_UNLOCK_RESPONSE = {
  data: DEVICE_TYPE
}

export const putDeviceSerialnumberUnlock = (partnerUuid: string, serialnumber: string) =>
  callApi<null, PUT_DEVICE_UNLOCK_RESPONSE>({
    method: "PUT",
    path: `/partner/${partnerUuid}/device/${serialnumber}/unlock`,
  })
