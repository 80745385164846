import React, { useEffect, useState } from "react"
import { Button, Row, Col, Container, Spinner, Alert, DropdownButton, Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { colors, config } from "@src/constants"
import { validation } from "@src/utils/validation"
import { SYSTEM_OF_MEASUREMENT, USER } from "@src/user"
import { useLocalization } from "@src/localization"
import { postUser } from "@src/api/requests/postUser"
import { useNavigate } from "react-router-dom"
import { InputField } from "@src/components"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"

const COLUMN = { marginTop: "10rem", borderRadius: 10 }

const styles = {
  divider: {
    backgroundColor: colors.gray,
    height: 1,
    width: "100%",
  },
}

export const RegisterUser: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useLocalization()
  const [newUser, setNewUser] = useState<USER>()
  const [loading, setLoading] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [systemOfMeasurement, setSystemOfMeasurement] = useState<SYSTEM_OF_MEASUREMENT>()
  const units = [SYSTEM_OF_MEASUREMENT.METRIC, SYSTEM_OF_MEASUREMENT.IMPERIAL]
  const navigate = useNavigate()

  useEffect(() => {
    let langData = { ...newUser, ["langcode"]: language }
    setNewUser(langData)
  }, [])

  const onInputPersist = (field: string) => (text: string) => {
    setShowAlert(false)
    const newUserData = { ...newUser, [field]: text }
    setNewUser(newUserData)
  }

  const onSubmit = () => {
    setLoading(true)
    postUser({ user: newUser }).then(({ response, data }) => {
      setLoading(false)
      if (response.ok) {
        setShowAlert(true)
        setNewUser(null)
      } else {
        setShowAlert(true)
      }
      setTimeout(() => {
        setShowAlert(false)
      }, config.alertSuccessTimeOut)
    })
  }

  let enabled =
    !validation.regex.email.test(newUser && newUser.email) ||
    !validation.regex.password.test(newUser && newUser.password)

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="10" lg="8" className="shadow-lg bg-body p-4" style={COLUMN}>
          <h4 className="large d-flex">{t("login_screen.register_user")}</h4>
          <div className="mt-2 mb-4" style={styles.divider}></div>
          <InputField
            label={t("register_user.firstname")}
            value={newUser && newUser.firstname}
            onChangeText={onInputPersist("firstname")}
            onPersist={onInputPersist("firstname")}
            validate={newUser && newUser.firstname == ""}
            errorMsg={t("register_user.validation.firstnameValid")}
            loading={false}
            isFocused={true}
          />
          <InputField
            label={t("register_user.lastname")}
            value={newUser && newUser.lastname}
            onPersist={onInputPersist("lastname")}
            onChangeText={onInputPersist("lastname")}
            loading={false}
            validate={newUser && newUser.lastname == ""}
            errorMsg={t("register_user.validation.lastnameValid")}
          />

          <InputField
            label={t("register_user.email")}
            value={newUser && newUser.email}
            onPersist={onInputPersist("email")}
            onChangeText={onInputPersist("email")}
            loading={false}
            validate={!validation.regex.email.test(newUser && newUser.email)}
            errorMsg={t("register_user.validation.emailValid")}
            isMandatory={true}
          />

          <InputField
            label={t("register_user.password")}
            value={newUser && newUser.password}
            onPersist={onInputPersist("password")}
            validate={!validation.regex.password.test(newUser && newUser.password)}
            onChangeText={onInputPersist("password")}
            errorMsg={t("register_user.validation.passwordValid")}
            loading={false}
            isMandatory={true}
          />

          <InputField
            label={t("register_user.address")}
            value={newUser && newUser.address}
            onPersist={onInputPersist("address")}
            validate={newUser && newUser.address == ""}
            loading={false}
          />

          <>
            <b className="small">{t("settings.user.unit")}:</b>
            <div className="d-flex flex-row align-items-center">
              <DropdownButton
                className="mt-2 me-2"
                id="time-interval-picker"
                title={
                  systemOfMeasurement ? t(`settings.user.${systemOfMeasurement}`) : t("settings.user.select_unit")
                }>
                {units.map((item, i) => (
                  <Dropdown.Item
                    key={item}
                    eventKey="1"
                    onClick={() => {
                      // setLoading(true)
                      const newUserData = { ...newUser, system_of_measurement: item }
                      setNewUser(newUserData)
                      setSystemOfMeasurement(item)
                    }}>
                    {t(`settings.user.${item}`)}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {loading && <Spinner style={sortArrowStyles.loader} animation="border" className="mt-2" />}
            </div>
          </>
          <Button type="submit" className="text-white mt-3" disabled={enabled} onClick={onSubmit}>
            {loading ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              t("register_user.submit").toUpperCase()
            )}
          </Button>
          <div className="mt-2">
            <label> {t("register_user.already_account")} </label>
            <a className="pointer" onClick={() => navigate("/login")}>
              {t("register_user.clickHere")}
            </a>
          </div>

          {showAlert && (
            <Alert className="mt-3" variant={!newUser ? "success" : "danger"}>
              {!newUser
                ? t("register_user.validation.user_created_success")
                : t("register_user.validation.email_already_exist")}
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  )
}
