import { callApi } from "../callApi"

export enum ROLES_TITLE {
  role_admin = "fleet_operator_admin",
  role_manager = "fleet_operator_manager",
  role_user = "fleet_user",
}

export type USER_ROLE = {
  id: number
  email: string
  firstname: string
  lastname: string
  role?: ROLES_TITLE
}

export type PARTNER_USERS = {
  name: ROLES_TITLE
  users: USER_ROLE[]
}

export const getPartnerUuidRoles = async (partnerUuid: string) => {
  return callApi<null, PARTNER_USERS[]>({
    method: "GET",
    path: `/partner/${partnerUuid}/roles`,
  })
}
