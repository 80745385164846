import React, { CSSProperties, act, useEffect, useState } from "react"
import { Table, Spinner, Alert, Button } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Page, PaginationItem, PermissionCard } from "@src/components"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import * as Icon from "react-bootstrap-icons"
import { usePartner } from "@src/partner"
import { colors, config } from "@src/constants"
import { DEVICE_LOADING_STATE, useDevice } from "@src/device/useDevice"
import { SortArrowIcon } from "@src/device/devicedetail/tabs/components"
import { getPartnerExpiredStatistics } from "@src/api/requests/statistics/getPartnerStatistics"
import { useFleets } from "@src/settings"
import { getDevice } from "@src/api"

export const TABLE_COLUMNS = [
  { key: "serialnumber", value: "serialnumber" },
  { key: "name", value: "device_name" },
  { key: "servicebooking_ends", value: "servicebooking_ends" },
  { key: "expiration_days", value: "expiration_days" },
]
const DAY_IN_MILLISECONDS: number = 24 * 60 * 60 * 1000
const NOWRAP: CSSProperties = { whiteSpace: "nowrap" }

export const colorTagStyle = (colors: string) => ({
  color: colors,
})

export const ExpiresSoon: React.FC = () => {
  const navigate = useNavigate()
  const { formatDateTime } = useLocalization()
  const { t } = useTranslation()
  const { partner } = usePartner()
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [sorting, setSorting] = useState<boolean>(true)
  const [sortHeader, setSortedHeader] = useState<string>("expiration_days")
  const [headerLoad, setHeaderLoad] = useState<boolean>(false)
  const { loadDevice } = useDevice()
  const { state } = useLocation()
  const [hitApi, setHitApi] = useState<boolean>(false)
  const [activePage, setActivePage] = useState<number>(0)
  const [pagesCount, setPagesCount] = useState<number>(1)
  const { fleet } = useFleets()

  useEffect(() => {
    if (partner) {
      fetchDevices()
      // getPartnerExpired()
    }
  }, [partner, activePage, fleet])

  const fetchDevices = async () => {
    setLoading(true)
    setHitApi(false)
    getDevice(partner?.uuid, fleet && fleet.uuid, { per_page: config.itemsPerPage }).then(({ response, data }) => {
      if (response.ok) {
        const data_devices = data.devices
        const runningOutServiceBookingDevices = data_devices.filter(
          (device) =>
            device.servicebooking !== null &&
            new Date(device.servicebooking.service_end_date).getTime() - new Date().getTime() <=
              60 * DAY_IN_MILLISECONDS,
        )
        setDevices(runningOutServiceBookingDevices)
        const sorted_devices = runningOutServiceBookingDevices.sort((a: SLIM_DEVICE_TYPE, b: SLIM_DEVICE_TYPE) => {
          const dateA = new Date(a.servicebooking.service_end_date).getTime()
          const dateB = new Date(b.servicebooking.service_end_date).getTime()
          return dateA - dateB
        })
        setDevices(sorted_devices)
        setLoading(false)
      }
    })
  }

  // const getPartnerExpired = async () => {
  //   setLoading(true)
  //   setHitApi(false)
  //   getPartnerExpiredStatistics(partner?.uuid, {
  //     partner_id: partner?.uuid,
  //     page: activePage,
  //     per_page: 30,
  //     months: 2,
  //   }).then(({ response, data }) => {
  //     if (response.ok) {
  //       setLoading(false)
  //       setDevices(data.devices)
  //       setPagesCount(Math.ceil(data.meta.total / data.meta.per_page))
  //     }
  //   })
  // }

  useEffect(() => {
    if (state != null && state.devices) {
      const sorted_devices = state.devices.sort((a: SLIM_DEVICE_TYPE, b: SLIM_DEVICE_TYPE) => {
        const dateA = new Date(a.servicebooking.service_end_date).getTime()
        const dateB = new Date(b.servicebooking.service_end_date).getTime()
        return dateA - dateB
      })
      setDevices(sorted_devices)
      setLoading(false)
    } else {
      setHitApi(true)
    }
  }, [state])

  const handleOnClick = (item: SLIM_DEVICE_TYPE) => {
    loadDevice(item.serialnumber, DEVICE_LOADING_STATE.load)
    navigate(`/${partner?.uuid}/device/${item.serialnumber}`)
  }

  const onClickTableHeader = (selectedHeader: string) => {
    if (selectedHeader !== sortHeader) {
      setSorting(true)
    } else {
      setSorting(!sorting)
    }
    if (selectedHeader == "expiration_days") {
      setSortedHeader(selectedHeader)
      setHeaderLoad(true)
      const sorted_devices = devices.sort((a: SLIM_DEVICE_TYPE, b: SLIM_DEVICE_TYPE) => {
        const dateA = new Date(a.servicebooking.service_end_date).getTime()
        const dateB = new Date(b.servicebooking.service_end_date).getTime()
        return !sorting ? dateA - dateB : dateB - dateA
      })
      setDevices(sorted_devices)
      setHeaderLoad(false)
    }
  }

  const changePage = (page: number) => {
    if ((page < 0 || pagesCount < page) && !!partner) return
    setLoading(activePage != page)
    setActivePage(page)
  }

  if (!partner?.permissions?.can_see_device_list) return <PermissionCard />

  return (
    <Page
      showHeader
      headerValue={t("dashboard_screen.devices_with_ending_servicebookings", { value: "60" })}
      onSelectFleet={() => {
        setLoading(true)
        setDevices([])
      }}>
      <div className="d-flex justify-content-between mt-1 mb-1">
        <PaginationItem activePage={activePage} pagesCount={pagesCount} onChangePage={(page) => changePage(page)} />
        <Button
          style={{ height: "38px" }}
          onClick={() => {
            navigate(`/${partner?.uuid}/settings/partner?tab=book_tracker`, { state: { devices } })
          }}>
          {t("settings.partner.book_service.extend_service")}
        </Button>
      </div>

      {loading && (
        <div className="d-flex justify-content-center mt-4 mb-4">
          <Spinner animation="border" />
        </div>
      )}
      {devices.length == 0 && !loading && <Alert variant={"secondary"}>{t("devices_screen.no_device")}</Alert>}
      {devices.length > 0 && !loading && (
        <Table hover responsive>
          <thead>
            <tr style={NOWRAP}>
              {TABLE_COLUMNS.map(({ key, value }) => (
                <th
                  key={key}
                  onClick={() => onClickTableHeader(key)}
                  className={key == "expiration_days" ? "pointer" : ""}>
                  {value && (
                    <div className="d-flex flex-row align-items-center">
                      {t(`devices_screen.${value}`)}
                      {sortHeader == key && <SortArrowIcon loading={headerLoad} arrowUp={!sorting} />}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {devices &&
              devices.map((device, i) => (
                <tr key={i} onClick={() => handleOnClick(device)} style={NOWRAP} className="pointer">
                  <td>
                    <div className="d-flex flex-row justify-content-between">
                      <div>{device.serialnumber}</div>
                      <div>
                        {device.powersaving_ends_at && <Icon.LightningChargeFill color={colors.mustardYellow} />}
                      </div>
                    </div>
                  </td>
                  <td>{device.name}</td>
                  <td>
                    {(device?.servicebooking && formatDateTime(new Date(device.servicebooking.service_end_date))) ||
                      "-"}
                  </td>
                  <td>
                    {(device?.servicebooking &&
                      Math.round(
                        (new Date(device.servicebooking.service_end_date).getTime() - new Date().getTime()) /
                          DAY_IN_MILLISECONDS,
                      )) ||
                      "-"}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Page>
  )
}
