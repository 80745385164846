import React, { useEffect, useState } from "react"
import { ListGroup, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { getReceivedInvitations, INVITES } from "@src/api"

export const InvitationsList: React.FC = () => {
  const [invitations, setInvitations] = useState<INVITES[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    receivedInvitations()
  }, [])

  const receivedInvitations = async () => {
    const response = await getReceivedInvitations()
    setInvitations(response.data)
  }
  if (invitations.length === 0) return null
  return (
    <Form className="mt-4 mb-4 ms-3 me-3 loginform_2">
      <Form.Label className="fw-bold">{t("invite_user.pending_invitations").toUpperCase()}</Form.Label>
      <label className="small mb-2">{t("invite_user.email_invite_desc")}</label>
      <ListGroup defaultActiveKey="#link1">
        {invitations?.map((item, i) => (
          <ListGroup.Item key={i} disabled={true} action onClick={() => {}}>
            <div className="mt-2 d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex">{item?.fleet_operator.name}</div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Form>
  )
}
