import { callApi } from "../callApi"

export type PUT_DEVICES_FENCE_BODY = {
  device_serialnumbers: string[]
}

export const putFleetsUuidFencesIdAddDevices = async (
  partnerUuid: string,
  fleet_id: string,
  fence_id: number,
  body: PUT_DEVICES_FENCE_BODY,
) => {
  return callApi<PUT_DEVICES_FENCE_BODY, null>({
    method: "PUT",
    path: `/partner/${partnerUuid}/fleets/${fleet_id}/fences/${fence_id}/add_devices`,
    body,
  })
}
