import React from "react"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { Card, Col, ListGroup, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { StyleSheet } from "@src/types"
import { InfoCircle } from "react-bootstrap-icons"

const styles: StyleSheet = {
  cardBody: {
    display: "flex",
    flexDirection: "column",
    height: "250px",
  },
  listGroup: {
    overflow: "scroll",
    flex: 1,
    display: "flex",
  },
  options: {
    flex: 1,
    borderWidth: 3,
    borderColor: "#F0F0F0",
    borderRadius: "30px",
  },
  cardTitle: {
    color: "#636E72",
  },
}

type DevicesCardProps = {
  title: string
  devices: SLIM_DEVICE_TYPE[]
  loading: boolean
  showDetail?: boolean
  tooltip?: string
  onDetailClick?: (devices: SLIM_DEVICE_TYPE[]) => void
  onDeviceClick: (device: SLIM_DEVICE_TYPE) => void
  formatDeviceTitle: (device: SLIM_DEVICE_TYPE) => string
}

const pageClassName = "shadow-2 bg-white rounded mt-1 "

export const DevicesCard: React.FC<DevicesCardProps> = ({
  title,
  devices,
  loading,
  showDetail,
  tooltip,
  onDetailClick,
  onDeviceClick,
  formatDeviceTitle,
}) => {
  const { t } = useTranslation()
  const { brand } = usePartner()

  const Link = ({ id, children }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{tooltip}</Tooltip>}>
      <a href="#">{children}</a>
    </OverlayTrigger>
  )

  return (
    <Col style={{ display: "flex" }}>
      <Card style={styles.options} className={pageClassName}>
        <Card.Body style={styles.cardBody}>
          <div className="d-flex justify-content-between">
            <Card.Title className="fs-5" style={styles.cardTitle}>
              {title}
            </Card.Title>
            <div className="ps-1" style={{ width: "35px" }}>
              <Link id="info">
                <InfoCircle color="#636E72" size={15} />
              </Link>
            </div>
          </div>
          <div
            style={{
              overflowY: "auto", // Enables vertical scrolling
              overflowX: "hidden", // Prevents horizontal scrolling
            }}>
            <ListGroup>
              {devices.length === 0 && !loading ? (
                <ListGroup.Item>{t("dashboard_screen.no_devices")}</ListGroup.Item>
              ) : (
                <>
                  {devices.map((device) => (
                    <ListGroup.Item
                      key={device.serialnumber}
                      className="list-group-item list-group-item-action pointer mb-1"
                      style={{ backgroundColor: "#F8F9FA" }}
                      as="a"
                      onClick={() => onDeviceClick(device)}>
                      {formatDeviceTitle(device)}
                    </ListGroup.Item>
                  ))}
                </>
              )}
              {loading && devices.length == 0 && <Spinner style={sortArrowStyles.loader} animation="border" />}
            </ListGroup>
          </div>
          <div className="d-flex flex-row-reverse mt-2">
            {showDetail && (
              <label className="pointer" onClick={() => onDetailClick(devices)}>
                {t("dashboard_screen.detail")}
              </label>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}
