import { callApi } from "../callApi"

type POST_ILOCKIT_LOCKSTATUS_PARAMS = {
  partnerUuid: string
  serialnumber: string
}
type POST_ILOCKIT_LOCKSTATUS_BODY = {
  open: boolean
}
type POST_ILOCKIT_LOCKSTATUS_RESPONSE = {
  attributes: {
    direction: "open" | "close"
  }
  description: string
  deviceId: number
  eventId: number
  id: number
  textChannel: boolean
  ttl: number
  type: string
}

export const postiLockitLockStatus = async (
  { partnerUuid, serialnumber }: POST_ILOCKIT_LOCKSTATUS_PARAMS,
  body: POST_ILOCKIT_LOCKSTATUS_BODY,
) =>
  callApi<POST_ILOCKIT_LOCKSTATUS_BODY, POST_ILOCKIT_LOCKSTATUS_RESPONSE>({
    method: "POST",
    path: `/gsm/${serialnumber}/lock`,
    body,
    endpointType: "ilockit",
    partnerUuid,
  })
