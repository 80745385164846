import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import { Col, Spinner } from "react-bootstrap"
import { putPartnerUuidFleetsUuid } from "@src/api"
import { validation } from "@src/utils"
import { InputField } from "@src/components"
import { FLEETS_CONTACT } from "../types"
import { useFleets } from "../useFleets"

export const FleetContactScreen: React.FC = () => {
  const { t } = useTranslation()
  const { fleet } = useFleets()
  const { partner } = usePartner()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingField, setLoadingField] = useState<string>("")
  const [contact, setContact] = useState<FLEETS_CONTACT>()
  const [email, setEmail] = useState<string>("")

  useEffect(() => {
    if (fleet && fleet.contact && fleet.contact.email) {
      setEmail(fleet.contact.email)
    }
  }, [fleet])

  const onInputPersist = (field: string) => (text: string) => {
    setLoadingField(field)
    const contactData = { ...contact, [field]: text }
    setContact(contactData)
    const updateData = { name: fleet.name, contact_attributes: contactData }
    putPartnerUuidFleetsUuid(partner.uuid, fleet.uuid, { fleet: updateData }).then(({ response, data }) => {
      if (response.ok) setLoadingField("")
    })
  }

  useEffect(() => {
    setLoading(true)
    if (fleet) {
      setLoading(false)
      setContact(fleet.contact)
    }
  }, [fleet])

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <Col xs={12} md={6} lg={4}>
          <InputField
            label={t("settings.fleets.name")}
            value={fleet && fleet.contact && fleet.contact.name}
            onPersist={onInputPersist("name")}
            loading={loadingField == "name"}
          />
          <InputField
            label={t("settings.fleets.email")}
            value={fleet && fleet.contact && fleet.contact.email}
            onPersist={onInputPersist("email")}
            loading={loadingField == "email"}
            onChangeText={(text) => setEmail(text)}
            validate={!validation.regex.email.test(email)}
            errorMsg={t("settings.fleets.validEmailError")}
          />
          <InputField
            label={t("settings.fleets.phone")}
            value={fleet && fleet.contact && fleet.contact.phone}
            onPersist={onInputPersist("phone")}
            loading={loadingField == "phone"}
          />
          <InputField
            label={t("settings.fleets.address")}
            value={fleet && fleet.contact && fleet.contact.address}
            onPersist={onInputPersist("address")}
            loading={loadingField == "address"}
          />
        </Col>
      )}
    </div>
  )
}
