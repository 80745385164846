export const colors = {
  gray: "#d3d2cd",
  green: "#008000",
  mustardYellow: "#eba834",
  red: "#e7001f",
  darkGray: "#111",
  middleGray: "#ededed",
  black: "#000000",
  white: "#ffffff",
  middleBlack: "#282c34",
  transparentBlack: "rgba(0,0,0,0.6)",
  darkViolet: "#1B0439",
  pink: "#EC516B",
  orangeRed: "#FF5E48",
  mustardyellow: "#FDC300",
  purple: "#AC23FF",
  maroon: "#D0021B",
  middleGreen: "#417505",
  blue: "#4A90E2",
  lightBlue: "#50E3C2",
  mildGreen: "#B8E986",
  superDarkGray: "#4A4A4A",
  lightGray: "#9B9B9B",
  darkGreen: "#138808",
  iconGray: "#3E3E3E",
  whiteGray: "#DCDCDC",
  smokeWhite: "#F5F5F5",
  gentleGray: "#DCDCDC",
}
