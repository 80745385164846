import { callApi } from "../callApi"
import { POST_FENCE_BODY, POST_FENCE_RESPONSE } from "./postFleetsUuidFences"

export const putFleetsUuidFenceId = async (
  partnerUuid: string,
  fleet_id: string,
  fence_id: number,
  body: POST_FENCE_BODY,
) => {
  return callApi<POST_FENCE_BODY, POST_FENCE_RESPONSE>({
    method: "PUT",
    path: `/partner/${partnerUuid}/fleets/${fleet_id}/fences/${fence_id}`,
    body,
  })
}
