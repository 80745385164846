import React, { createContext, useContext, useEffect, useState } from "react"
import { getDeviceSerialnumber, getDeviceSerialnumberTrips } from "@src/api"
import { DEVICE_TYPE } from "@src/devices/types"
import { usePartner } from "@src/partner"
import { patchDeviceSerialNumber as patchDeviceData } from "@src/api"
import { TRIP } from "@src/api/types"
import { getiLockitLockStatus } from "@src/api/ilockit"

export enum DEVICE_LOADING_STATE {
  load,
  patch,
  lock,
  powerSave,
}

type DEVICE_CONTEXT = {
  device: DEVICE_TYPE
  trips: TRIP[]
  loading: DEVICE_LOADING_STATE
  loadDevice: (serialNumber: string, loadState?: DEVICE_LOADING_STATE) => void
  loadLastTrips: (serialNumber: string) => void
  patchDevice: (deviceData: DEVICE_TYPE) => Promise<boolean>
  clearDevice: () => void
  clearLastTrips: () => void
}

const DEFAULT_DEVICE_CONTEXT = {
  device: null,
  trips: [],
  loading: null,
  loadDevice: () => {},
  loadLastTrips: () => {},
  patchDevice: async () => false,
  clearDevice: () => {},
  clearLastTrips: () => {},
}

export const DeviceContext = createContext<DEVICE_CONTEXT>(DEFAULT_DEVICE_CONTEXT)

export const DeviceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { partner } = usePartner()
  const [device, setDevice] = useState<DEVICE_TYPE>()
  const [loading, setLoading] = useState<DEVICE_LOADING_STATE>()
  const [trips, setTrips] = useState<TRIP[]>([])

  const loadDevice = (serialNumber: string, loadState?: DEVICE_LOADING_STATE) => {
    setLoading(loadState)
    getDeviceSerialnumber(partner?.uuid, serialNumber).then(({ response, data }) => {
      setLoading(null)
      if (response.ok) {
        getiLockitLockStatus(partner?.uuid, serialNumber).then(({ response: keyHubResponse, data: keyHubData }) => {
          if (keyHubResponse.ok) {
            setDevice({ ...data, ilockitCredentials: keyHubData })
          } else {
            setDevice(data)
          }
        })
      }
    })
  }

  const patchDevice = async (deviceData: DEVICE_TYPE) => {
    let { response, data } = await patchDeviceData(partner?.uuid, deviceData)
    if (response.ok) {
      setDevice(deviceData) //TO DO: The backend response of devicedata is incomplete. so we return the boolean of the success of the API to retain the changes.
      return true
    }
    return false
  }

  const loadLastTrips = (serialNumber: string) => {
    getDeviceSerialnumberTrips(partner?.uuid, serialNumber, { per_page: 500 }).then(({ response, data }) => {
      if (response.ok) {
        setTrips(data.trips)
      }
    })
  }

  const clearDevice = () => {
    setDevice(null)
  }

  const clearLastTrips = () => {
    setTrips([])
  }

  const context = {
    device,
    trips,
    loading,
    loadDevice,
    loadLastTrips,
    patchDevice,
    clearDevice,
    clearLastTrips,
  }

  return <DeviceContext.Provider value={context}>{children}</DeviceContext.Provider>
}

export const useDevice = () => useContext(DeviceContext)
