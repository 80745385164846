import { callApi } from "../callApi"

export type NOTIFICATION_SETINGS = {
  battery_low?: boolean
  stolen?: boolean
  locked?: boolean
  custom?: boolean
  locked_movement?: boolean
  power_switched_on?: boolean
  power_switched_off?: boolean
  locked_distance?: boolean
  unlocking_inactive_user?: boolean
  left_geofence?: boolean
  crash?: boolean
  left_service_area?: boolean
  sending_problem_detected?: boolean
  battery_very_low?: boolean
  trip_begin?: boolean
  user_added?: boolean
  entered_geofence?: boolean
  emergency_assistant_available?: boolean
  emergency_assistant_not_available?: boolean
  bike_telemetry?: boolean
  service_expiring?: boolean
}

export const getFleetsUuidSettings = async (partnerUuid: string, fleetUuid: string) => {
  return callApi<null, NOTIFICATION_SETINGS>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/settings`,
  })
}
