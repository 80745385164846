import React, { createContext, useContext, useEffect } from "react"
import dayjs from "dayjs"
import { localization } from "@src/config"
import { usePersistedState } from "@src/utils"
import { validateLanguage, validateRegion } from "./helpers"
import i18n from "./i18n"
import relativeTIme from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc" // Add this line
import timezone from "dayjs/plugin/timezone"
import { useTranslation } from "react-i18next"
import "dayjs/locale/de"
import "dayjs/locale/en"
import "dayjs/locale/fr"
import "dayjs/locale/es"

type LOCALIZATION_CONTEXT = {
  language: string
  region: string
  formatDate: (date: Date) => string
  formatTime: (date: Date) => string
  formatDateTime: (date: Date) => string
  formatCurrency: (value: string) => string
  isLaterThenToday: (date: Date) => boolean
  isValidFormat: (date: Date) => boolean
  changeLanguage: (lang: string) => void
  changeRegion: (region: string) => void
  durationFromDate: (date: string) => string
}

const DEFAULT_LOCALIZATION_CONTEXT = {
  language: localization.defaultLang,
  region: localization.defaultRegion,
  formatDate: () => "",
  formatTime: () => "",
  formatDateTime: () => "",
  formatCurrency: () => "",
  isLaterThenToday: () => false,
  isValidFormat: () => false,
  changeLanguage: () => {},
  changeRegion: () => {},
  durationFromDate: () => "",
}

const browserLocalization = navigator.language // example: en-DE
const SYSTEM_LANGUAGE = browserLocalization.substring(0, 2)
const SYSTEM_REGION = browserLocalization.substring(3, 5)

export const LocalizationContext = createContext<LOCALIZATION_CONTEXT>(DEFAULT_LOCALIZATION_CONTEXT)

export const LocalizationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  const [language, setLanguage, languageFinished] = usePersistedState<string>(
    "@language",
    validateLanguage(SYSTEM_LANGUAGE),
  )
  const [region, setRegion] = usePersistedState<string>("@region", validateRegion(SYSTEM_REGION))

  useEffect(() => {
    changeLanguage(language)
  }, [languageFinished])

  useEffect(() => {
    changeRegion(region)
  }, [])

  const changeLanguage = (lang: string) => {
    const newLang = validateLanguage(lang)
    i18n.changeLanguage(newLang)
    setLanguage(newLang)
    // if (newLang == "de") {
    //   require("dayjs/locale/de");
    // } else if (newLang == "en") {
    //   require("dayjs/locale/en");
    // } else if (newLang == "fr") {
    //   require("dayjs/locale/fr");
    // } else {
    //   require("dayjs/locale/es");
    // }
    dayjs.locale(newLang)
  }

  const changeRegion = (region: string) => {
    setRegion(validateRegion(region))
  }

  const format = (date: Date, pos: number): string => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    if (region) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      return dayjs(date).utc().tz(timezone).format(localization.localizationFormat[region][pos])
    }
  }
  const formatDate = (date: Date) => format(date, 0)
  const formatTime = (date: Date) => format(date, 1)
  const formatDateTime = (date: Date) => format(date, 2)
  const formatCurrency = (value: string) => (i18n.language === "en" ? value : value.replace(".", ",")) //(i18n.getLocale() === "en" ? value : value.replace(".", ","))
  const isLaterThenToday = (date: Date) => dayjs().isAfter(dayjs(date))
  const isValidFormat = (date: Date) => dayjs(date).isValid()

  const getTimeUnit = (relativeTime: string) => {
    const units = ["second", "minute", "hour", "day", "month", "year"]
    const unit = units.find((unit) => relativeTime.includes(unit))
    if (unit) {
      if (relativeTime.startsWith("a ")) {
        return { quantity: 1, unit }
      }
      const quantity = parseInt(relativeTime.split(" ")[0], 10)
      return { quantity, unit }
    }
    return null
  }

  const durationFromDate = (date: string) => {
    dayjs.extend(relativeTIme)
    const relativeTime = dayjs(date).fromNow()
    const timeUnitData = getTimeUnit(relativeTime)
    if (timeUnitData) {
      const { quantity, unit } = timeUnitData
      const translationKey = `map.${unit}_${quantity == 1 ? "singular" : "plural"}`
      const translatedUnit = t(translationKey)
      return quantity + " " + translatedUnit
    }
    return relativeTime
  }

  const context = {
    language: language || validateLanguage(SYSTEM_LANGUAGE),
    region,
    formatDate,
    formatTime,
    formatDateTime,
    formatCurrency,
    isLaterThenToday,
    isValidFormat,
    changeLanguage,
    changeRegion,
    durationFromDate,
  }

  return <LocalizationContext.Provider value={context}>{children}</LocalizationContext.Provider>
}

export const useLocalization = () => useContext(LocalizationContext)
