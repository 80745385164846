import { translations } from "@src/localization/translations/translations"

const regionConfig = {
  // You can find all the available parsing tokens here: https://day.js.org/docs/en/parse/string-format#list-of-all-available-parsing-tokens
  // "langKey": ["dateFormat", "timeFormat", "dateTimeFormat"],
  DE: ["DD.MM.YYYY", "HH:mm", "DD.MM.YYYY HH:mm", "dd/MM/yyyy"],
  US: ["MM.DD.YYYY", "h.mm a", "MM.DD.YYYY h.mm a", "MM/dd/yyyy"],
  GB: ["MM.DD.YYYY", "HH:mm", "MM.DD.YYYY HH:mm", "MM/dd/yyyy"],
  NL: ["DD.MM.YYYY", "HH:mm", "DD.MM.YYYY HH:mm", "dd/MM/yyyy"],
  FR: ["DD.MM.YYYY", "HH:mm", "DD.MM.YYYY HH:mm", "dd/MM/yyyy"],
  IT: ["DD.MM.YYYY", "HH:mm", "DD.MM.YYYY HH:mm", "dd/MM/yyyy"],
}

export const localization = {
  defaultLang: "en",
  defaultRegion: "DE",
  languages: Object.keys(translations),
  regions: Object.keys(regionConfig),
  localizationFormat: regionConfig,
}
