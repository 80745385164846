import { callApi } from "@src/api/callApi"

type POST_DEVICE_DISABLE_POWER_SAVE_BODY = {
  device_serialnumbers: string[]
}

export const postDeviceBatchDisablePowersave = (partnerUuid: string, body: POST_DEVICE_DISABLE_POWER_SAVE_BODY) =>
  callApi<POST_DEVICE_DISABLE_POWER_SAVE_BODY, null>({
    method: "POST",
    path: `/partner/${partnerUuid}/device/batch_disable_powersaving`,
    body,
  })
