import { callApi } from "../callApi"
import { FENCE } from "@src/settings"
import { META_PARAMS, GET_DEVICE_PARAMS } from "./getDevice"

type GET_FENCE_RESPONSE = {
  fences: [FENCE]
  meta: META_PARAMS
}

export const getFleetsUuidFences = async (partnerUuid: string, fleetUuid: string, params?: GET_DEVICE_PARAMS) => {
  const queryString = Object.keys(params || {})
    .map((key) => key + "=" + params[key])
    .join("&")
  return callApi<null, GET_FENCE_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/fences?${queryString}`,
  })
}
