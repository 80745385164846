import React from "react"
import { Page } from "@src/components"
import { useTranslation } from "react-i18next"
import { FenceDetailTab } from "./tabs/FenceDetailTab"
import { useLocalization } from "@src/localization"

export const CreateGeofenceScreen: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useLocalization()

  return (
    <Page
      isSettings
      showHeader
      headerValue={language == "it" ? t("settings.fence.new_fence_it") : t("settings.fence.new_fence")}>
      <FenceDetailTab />
    </Page>
  )
}
