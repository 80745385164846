import React, { useEffect, useState } from "react"
import { postDeviceBatchDisablePowersave, postDeviceBatchEnablePowersave } from "@src/api/requests"
import { Spinner, Button, Modal, Dropdown, DropdownButton } from "react-bootstrap"
import { DEVICE_TYPE } from "@src/devices/types"
import { usePartner } from "@src/partner"
import { LightningCharge, LightningChargeFill } from "react-bootstrap-icons"
import { colors } from "@src/constants"
import { useTranslation } from "react-i18next"
import { ModalView } from "@src/components/ModalView"
import { useDevice, DEVICE_LOADING_STATE } from "@src/device/useDevice"

type PowerSaveButtonProps = {
  serialnumbers: string[]
}

export const PowerSaveButton: React.FC<PowerSaveButtonProps> = ({ serialnumbers }) => {
  const [show, setShow] = useState(false)
  const [timeInterval, setTimeInterval] = useState<number>(0)
  const { partner } = usePartner()
  const { t } = useTranslation()
  const { loadDevice, device } = useDevice()
  const [powerSaveOn, setPowerSaveOn] = useState<boolean>(device?.powersaving_ends_at ? true : false)
  const handleClose = () => setShow(false)
  const powerSaveIntervals = [4, 8, 24]
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(false)
  }, [device])

  const turnOnPowerSaving = async () => {
    setLoading(true)
    if (powerSaveOn) {
      await postDeviceBatchDisablePowersave(partner?.uuid, { device_serialnumbers: serialnumbers })
    } else {
      await postDeviceBatchEnablePowersave(partner?.uuid, {
        device_serialnumbers: serialnumbers,
        interval: timeInterval,
      })
    }
    device && loadDevice(device.serialnumber, null)
    setPowerSaveOn(!powerSaveOn)
  }

  return (
    <div>
      <ModalView
        title={t("device_screen.power_saving.energy_saving")}
        buttonTitle={powerSaveOn ? t("device_screen.power_saving.turn_off") : t("device_screen.power_saving.turn_on")}
        show={show}
        disableButton={!powerSaveOn && timeInterval == 0}
        isCenter={true}
        buttonLoader={false}
        onHideModal={handleClose}
        onButtonClick={() => {
          setShow(false)
          turnOnPowerSaving()
        }}>
        <p>{t("device_screen.power_saving.energy_limitations_msg")}</p>
        {!powerSaveOn && (
          <DropdownButton
            id="time-interval-picker"
            title={timeInterval == 0 ? "Select time interval" : timeInterval + " hours"}>
            {powerSaveIntervals.map((item, i) => (
              <Dropdown.Item key={item} eventKey="1" onClick={() => setTimeInterval(item)}>
                {item} {t("device_screen.power_saving.hours")}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </ModalView>
      <Button
        variant={"light"}
        className="ms-2 justify-content-center align-items-center"
        onClick={() => {
          setTimeInterval(0)
          setShow(true)
        }}
        disabled={loading}
        style={{
          borderRadius: ".25rem",
        }}>
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <>
            {!powerSaveOn ? (
              <LightningCharge className="align-self-center" size={16} color={colors.iconGray} />
            ) : (
              <LightningChargeFill className="align-self-center" size={16} color={colors.mustardYellow} />
            )}
          </>
        )}
      </Button>
    </div>
  )
}
