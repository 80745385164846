import React from "react"
import { NavDropdown } from "react-bootstrap"
import { useLocalization } from "@src/localization/useLocalization"
import { localization } from "@src/config"
import "./LanguagePicker.css"
import { useUser } from "@src/user"
import { usePartner } from "@src/partner"

const CONTAINER = { marginRight: "5px", marginTop: "2px" }

type LanguagePickerProps = {
  onChange?: (lang: string) => void
}
export const LanguagePicker: React.FC<LanguagePickerProps> = ({ onChange }) => {
  const { language, changeLanguage } = useLocalization()
  const { user, updateUser } = useUser()

  const changeEvent = (selectedKey) => {
    const userRequest = { ...user, ["langcode"]: selectedKey }
    updateUser(userRequest)
    changeLanguage(selectedKey)
  }

  return (
    <NavDropdown id="language-picker" title={language.toUpperCase()} onSelect={changeEvent} style={CONTAINER}>
      {localization.languages.map((langItem, i) => (
        <NavDropdown.Item
          href="#"
          key={i}
          eventKey={langItem}
          active={langItem == language}
          className={"language-picker-item align-self-center"}>
          {langItem.toUpperCase()}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  )
}
