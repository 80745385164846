import { callApi } from "../../callApi"
import { GET_DEVICE_RESPONSE } from "../getDevice"

export type GET_PARTNER_STATISTICS_PARAMS = {
  partner_id: string
  page: number
  per_page: number
  search_string?: string
  sort_by?: string
  sort_desc?: boolean
  months: number
}

export const getPartnerExpiredStatistics = async (partnerUuid: string, params: GET_PARTNER_STATISTICS_PARAMS) => {
  const queryString = Object.keys(params || {})
    .map((key) => key + "=" + params[key])
    .join("&")
  return callApi<null, GET_DEVICE_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/statistics/expired_servicebooking?${queryString}`,
  })
}