import { callApi } from "@src/api/callApi"
import { USER } from "@src/user"

type PUT_USER_BODY = {
  user: USER
}

type PUT_USER_RESPONSE = USER

export const putUsers = (body: PUT_USER_BODY) =>
  callApi<PUT_USER_BODY, PUT_USER_RESPONSE>({
    method: "PUT",
    path: "/users",
    body,
  })
