import React from "react"
import { Button, Modal, Spinner } from "react-bootstrap"

type ModalViewProps = {
  title: string
  show: boolean
  buttonTitle: string
  buttonLoader: boolean
  disabled?: boolean
  onHideModal: () => void
  onButtonClick: () => void
}

export const ModalView: React.FC<ModalViewProps> = ({
  title,
  show,
  buttonTitle,
  buttonLoader,
  disabled,
  children,
  onHideModal,
  onButtonClick,
}) => {
  return (
    <Modal show={show} onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body> {children} </Modal.Body>
      <Modal.Footer>
        <Button disabled={disabled} variant="primary" onClick={!buttonLoader ? onButtonClick : null}>
          {buttonLoader ? <Spinner animation="border" size="sm" /> : buttonTitle}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
