import React, { useEffect, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { Page, PermissionCard } from "@src/components"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { getDevice } from "@src/api"
import { useTranslation } from "react-i18next"
import { usePartner } from "@src/partner"
import { Map } from "@src/device/devicedetail/tabs/components"
import { useFleets } from "@src/settings"
import { colors, config } from "@src/constants"

export const MapScreen: React.FC = () => {
  const { t } = useTranslation()
  const { partner } = usePartner()
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const { fleet } = useFleets()
  const [loading, setLoading] = useState<boolean>(false)
  const styles = { warningCard: { backgroundColor: colors.whiteGray } }

  useEffect(() => {
    partner && fetchDevices()
  }, [partner?.uuid, fleet])

  const fetchDevices = async () => {
    setLoading(true)
    const { response, data } = await getDevice(partner?.uuid, fleet && fleet.uuid, { per_page: config.itemsPerPage })
    if (response.ok) {
      setDevices(data.devices)
      setLoading(false)
    }
  }

  const mapDevices = devices.filter((device) => device.position !== null && !device.position.error)
  const mapDevicePositions = mapDevices.map((device) => device.position)

  if (!partner?.permissions?.can_see_map) return <PermissionCard />

  return (
    <Page
      showFleet={true}
      showHeader={true}
      headerValue={t("sidebar.maps")}
      onSelectFleet={() => {
        setLoading(true)
        setDevices([])
      }}>
      <Map
        position={mapDevicePositions[0]}
        datapoints={mapDevicePositions}
        showRoute={false}
        showMultipleMarkers={true}
        loadDevices={loading}
        mapDevices={mapDevices}
      />
    </Page>
  )
}
