import React, { Component } from "react"
import { useTranslation } from "react-i18next"

export const PrivacyPolicyScreen: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="container content">
      <h1>
        <b>{t("privacy.header")}</b>
      </h1>
      <br />
      <b>{t("privacy.general_information")}</b>
      <br />
      {t("privacy.general_information_text")}
      <br />
      <br />
      <b>{t("privacy.data_collection")}</b>
      <br />
      {t("privacy.data_collection_text")}
      <br />
      <br />
      <b>{t("privacy.tools")}</b>
      <br />
      {t("privacy.tools_text")}
      <br />
      <br />
      <b>{t("privacy.external_host")}</b>
      <br />
      {t("privacy.external_host_text")}
      <br />
      <br />
      {t("privacy.hoster")}
      <br />
      jweiland.net
      <br />
      Echterdinger Straße 57
      <br />
      70794 Filderstadt {t("privacy.near")} Stuttgart
      <br />
      <br />
      <b>{t("privacy.order_processing")}</b>
      <br />
      {t("privacy.order_processing_text")}
      <br />
      <br />
      <b>{t("privacy.data_protection")}</b>
      <br />
      {t("privacy.data_protection_text")}
      <br />
      <br />
      <b>{t("privacy.resp_office")}</b>
      <br />
      {t("privacy.resp_office_text")}
      <br />
      <br />
      IoT Venture GmbH
      <br />
      Hilpertstr. 31
      <br />
      64295 Darmstadt
      <br />
      Deutschland
      <br />
      {t("privacy.telephone")}: +49 800 0060751
      <br />
      {t("privacy.email")}: info@iot-venture.com
      <br />
      <br />
      {t("privacy.resp_office_footer")}
      <br />
      <br />
      <b>{t("privacy.storage_period")}</b>
      <br />
      {t("privacy.storage_period_text")}
      <br />
      <br />
      <b>{t("privacy.data_process")}</b>
      <br />
      {t("privacy.data_process_text")}
      <br />
      <br />
      <b>{t("privacy.data_protection_officer")}</b>
      <br />
      {t("privacy.data_protection_officer_text")}
      <br />
      <br />
      <b>{t("privacy.data_transfer")}</b>
      <br />
      {t("privacy.data_transfer_text")}
      <br />
      <br />
      <b>{t("privacy.revocation")}</b>
      <br />
      {t("privacy.revocation_text")}
      <br />
      <br />
      <b>{t("privacy.marketing")}</b>
      <br />
      {t("privacy.marketing_text")}
      <br />
      <br />
      <b>{t("privacy.competent_supervisor")}</b>
      <br />
      {t("privacy.competent_supervisor_text")}
      <br />
      <br />
      <b>{t("privacy.data_portability")}</b>
      <br />
      {t("privacy.data_portability_text")}
      <br />
      <br />
      <b>{t("privacy.deletion")}</b>
      <br />
      {t("privacy.deletion_text")}
      <br />
      <br />
      <b>{t("privacy.restrict_process")}</b>
      <br />
      {t("privacy.restrict_process_text")}
      <br />
      <br />
      <b>{t("privacy.ssl")}</b>
      <br />
      {t("privacy.ssl_text")}
      <br />
      <br />
      <b>{t("privacy.cookies")}</b>
      <br />
      {t("privacy.cookies_text")}
      <br />
      <br />
      <b>{t("privacy.contact_form")}</b>
      <br />
      {t("privacy.contact_form_text")}
      <br />
      <br />
      <b>{t("privacy.inquiry")}</b>
      <br />
      {t("privacy.inquiry_text")}
      <br />
      <br />
      <b>{t("privacy.google_analytics")}</b>
      <br />
      {t("privacy.google_analytics_text")}
      <br />
      <a href="https://privacy.google.com/businesses/controllerterms/mccs/">
        https://privacy.google.com/businesses/controllerterms/mccs/.
      </a>
      <br />
      <br />
      <b>{t("privacy.browser_plugins")}</b>
      <br />
      {t("privacy.browser_plugins_text")}
      <br />
      <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de.</a>
      <br />
      {t("privacy.browser_plugins_more")}
      <br />
      <a href="https://support.google.com/analytics/answer/6004245?hl=de">
        https://support.google.com/analytics/answer/6004245?hl=de.
      </a>
      <br />
      <br />
      <b>{t("privacy.data_processing")}</b>
      <br />
      {t("privacy.data_processing_text")}
      <br />
      <br />
      <b>{t("privacy.purpose_legal")}</b>
      <br />
      {t("privacy.purpose_legal_text")}
      <br />
      <br />
      <b>{t("privacy.storage_period_2")}</b>
      <br />
      {t("privacy.storage_period_2_text")}
      <br />
      <br />
      <b>{t("privacy.conference_tools")}</b>
      <br />
      {t("privacy.conference_tools_text")}
      <br />
      {t("privacy.microsoft_teams")}
      <br />
      {t("privacy.microsoft_teams_text")}
      <br />
      <a href="https://privacy.microsoft.com/de-de/privacystatement">
        https://privacy.microsoft.com/de-de/privacystatement.
      </a>
      <br />
      <br />
      <b>{t("privacy.applicant_data")}</b>
      <br />
      {t("privacy.applicant_data_text")}
      <br />
      <br />
      <b>{t("privacy.scope_data_collection")} </b>
      <br />
      {t("privacy.scope_data_collection_text")}
      <br />
      <br />
      <b>{t("privacy.rentention_period")} </b>
      <br />
      {t("privacy.rentention_period_text")}
      <br />
      <br />
      <b>{t("privacy.applicant_pool")} </b>
      <br />
      {t("privacy.applicant_pool_text")}
      <br />
      <br />
      <br />
    </div>
  )
}
