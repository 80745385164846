import { callApi } from "@src/api/callApi"

type PUT_DEVICE_STOLEN = {
  device_serialnumber: string
  circumstanceofcrime: string
  specialfeatures: string
  stolen_timestamp_from: string
  stolen_timestamp_to: string
}

export const putDeviceSerialnumberStolen = (partnerUuid: string, body: PUT_DEVICE_STOLEN) =>
  callApi<PUT_DEVICE_STOLEN, null>({
    method: "PUT",
    path: `/partner/${partnerUuid}/device/${body.device_serialnumber}/stolen`,
    body,
  })
