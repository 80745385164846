import * as Icon from "react-bootstrap-icons"

type LockUnlockIconProps = {
  locked: "open" | "locked"
}
const ICON = { fontSize: "15px" }
export const LockUnlockIcon: React.FC<LockUnlockIconProps> = ({ locked }) => {
  if (locked == "locked") return <Icon.Lock style={ICON} />
  return <Icon.Unlock style={ICON} />
}
