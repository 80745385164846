import React, { useEffect } from "react"
import { LanguagePicker, LoginLogoutButton } from "./components"
import { Badge, Container, Nav, Navbar } from "react-bootstrap"
import { usePartner } from "@src/partner"
import { Link, useNavigate } from "react-router-dom"
import { env } from "@src/utils"
import * as Icon from "react-bootstrap-icons"
import { colors } from "@src/constants"

export const NavBar: React.FC = () => {
  const { brand, partner, partners } = usePartner()
  const navigate = useNavigate()

  return (
    <Navbar
      id="brand-navbar"
      collapseOnSelect
      expand="lg"
      className="navbar navbar-dark navbar-expand-lg brand-navbar"
      role="navigation">
      <Container>
        <Navbar.Brand className="">
          <Link to={`/${partner?.uuid || ""}`}>
            <img id="brand-logo" src={brand.logoUrl} height="70" />
          </Link>
          {env == "staging" && (
            <Badge className="ms-2" bg="danger">
              Staging
            </Badge>
          )}
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            {/* {partners?.length > 1 && ( */}
            <Nav.Link as={Link} to="/" style={{ color: colors.white, fontFamily: "IoTVenture-Bold" }} eventKey="">
              {partner?.name}
            </Nav.Link>
            {/* )} */}
          </Nav>
          <Nav className="me-auto"></Nav>
          <Nav>
            <LanguagePicker />
            {partner && (
              <Nav.Link
                onClick={() => {
                  navigate(`/${partner?.uuid}/settings/user`)
                }}
                eventKey="">
                <Icon.Gear className="me-2 align-self-center" color={colors.white} size={18} fontWeight="bold" />
              </Nav.Link>
            )}
            <LoginLogoutButton />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
