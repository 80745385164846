import { PARTNER_USERS } from "."
import { callApi } from "../callApi"

export type POST_ROLES_BODY = {
  user_ids: number[]
  role: string
}

export type POST_ROLES_RESPONSE = {
  roles: PARTNER_USERS[]
}

export const postPartnerUuidRoles = async (partnerUuid: string, body: POST_ROLES_BODY) => {
  return callApi<POST_ROLES_BODY, POST_ROLES_RESPONSE>({
    method: "POST",
    path: `/partner/${partnerUuid}/roles`,
    body,
  })
}
