import { callApi } from "@src/api/callApi"
import { POST_FLEET_RESPONSE } from "./postPartnerUuidFleets"

export type POST_FLEET_DEVICE_BODY = {
  device_serialnumbers: string[]
}

export const postFleetsUuidAddDevices = (partnerUuid: string, fleetUuid: string, body: POST_FLEET_DEVICE_BODY) =>
  callApi<POST_FLEET_DEVICE_BODY, POST_FLEET_RESPONSE>({
    method: "POST",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/add_devices`,
    body,
  })
