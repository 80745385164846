import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Card, ListGroup, Form, Spinner, DropdownButton, Dropdown } from "react-bootstrap"
import { sendEmailViaMailgun } from "@src/api"
import { useAuthentication } from "@src/auth"
import { ModalView } from "@src/components"
import { usePartner } from "@src/partner"
import { useUser } from "@src/user"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { getPartnerExpiredStatistics } from "@src/api/requests/statistics/getPartnerStatistics"
import { useLocation } from "react-router"
import { config } from "@src/constants"

const styles = {
  listGroup: {
    overflow: "scroll",
    flex: 1,
    display: "flex",
  },
  divBox: {
    display: "flex",
    flexDirection: "column" as const,
    height: "350px",
    marginBottom: "20px",
  },
  warningCard: { backgroundColor: "#f8f9fa" },
  toggleButton: { marginTop: "10px" },
  costBox: {
    display: "flex",
    flexDirection: "column" as const,
    marginTop: "15px",
    marginBottom: "15px",
  },
}

export const BookTrackerService: React.FC = () => {
  const { t } = useTranslation()
  const { userEmail, userId } = useAuthentication()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const { partner } = usePartner()
  const { user } = useUser()
  const [duration, setDuration] = useState<number>()
  const costPerYear = 20
  const VAT_RATE = 0.19
  const [searchText, setSearchText] = useState("")
  const [loading, setLoading] = useState(false)
  const [expiredDevices, setExpiredDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<SLIM_DEVICE_TYPE[]>([])
  const tariff = [12, 24, 36]
  // const [totalCostWithoutVAT, setTotalCostWithoutVAT] = useState<number>(0)
  // const [totalVAT, setTotalVAT] = useState<number>(0)
  // const [totalCostWithVAT, setTotalCostWithVAT] = useState<number>(0)
  const { state } = useLocation()

  useEffect(() => {
    if (partner) getExpiredDevices()
  }, [partner])

  const getExpiredDevices = async () => {
    setLoading(true)
    const response = await getPartnerExpiredStatistics(partner?.uuid, {
      partner_id: partner?.uuid,
      search_string: searchText,
      per_page: config.itemsPerPage,
      page: 0,
      months: 2,
    })
    if (response.response.ok) {
      const expiredDevices = response.data.devices
      setExpiredDevices(expiredDevices)
    }
    setLoading(false)
  }

  const handleDurationChange = (item: number) => {
    const year = item / 12
    setDuration(item)
    // const costWithoutVat = selectedCheckboxes.length * year * costPerYear
    // setTotalCostWithoutVAT(costWithoutVat)
    // const taxedValue = costWithoutVat * VAT_RATE
    // setTotalVAT(taxedValue)
    // setTotalCostWithVAT(costWithoutVat + taxedValue)
  }

  const handleRequestAddTrackers = async () => {
    setShowModal(false)
    setLoading(true)
    const deviceIds = selectedCheckboxes.map((ch) => ch.serialnumber).join(", ")
    const mailContent = `
      This is a generated message from the fleet portal: http://dashboard.iot-venture.com.\n
      "${partner?.name}" (${partner?.uuid}) has requested to extend the service of the following trackers:
      ${deviceIds}. Please contact the client shortly to discuss the pricing options for this service extension given below.\n
      Billing info:
      User ID: ${userId}
      Email: ${userEmail}
      Firstname: ${user.firstname}
      Lastname: ${user.lastname}
      Address: ${user.address}
      Selected service period: ${duration} months
    `

    const response = await sendEmailViaMailgun({
      from: "fleetportal@itsmybike.com",
      subject: "Tracker Service Extension Request",
      text: mailContent,
    })
    if (response.message) {
      setLoading(false)
      setShowSuccess(true)
      setSelectedCheckboxes([])
      setDuration(undefined)
      // setTotalCostWithVAT(0)
      // setTotalVAT(0)
      // setTotalCostWithoutVAT(0)
      window.history.replaceState({}, "")
    }
  }

  useEffect(() => {
    if (duration) {
      handleDurationChange(duration)
    }
  }, [selectedCheckboxes])

  useEffect(() => {
    if (state != null && state.serialnumber) {
      const device = expiredDevices.find((d) => d.serialnumber === state.serialnumber)
      setSelectedCheckboxes(device ? [device] : [])
    }
  }, [state, expiredDevices])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleCheckbox = (device: SLIM_DEVICE_TYPE) => {
    setSelectedCheckboxes((prev) =>
      prev.some((ch) => ch.serialnumber === device.serialnumber)
        ? prev.filter((ch) => ch.serialnumber !== device.serialnumber)
        : [...prev, device],
    )
  }

  const monthYear = (item: number) => {
    return t(item == 12 ? "settings.partner.book_service.month_year" : "settings.partner.book_service.month_years", {
      month_value: item,
      year_value: item / 12,
    })
  }

  return (
    <div>
      {showSuccess && (
        <Card style={styles.warningCard} className="mt-3 mb-4 p-2">
          {t("settings.partner.mail_toast")}
        </Card>
      )}

      <Form.Control
        type="search"
        id="search"
        autoComplete="off"
        placeholder={t("sidebar.search_placeholder")}
        value={searchText}
        onChange={onSearchChange}
      />
      <div style={styles.divBox}>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <Spinner size="sm" animation="border" />
          </div>
        ) : (
          <ListGroup className="mt-2" style={styles.listGroup}>
            {expiredDevices.map((result) => (
              <ListGroup.Item key={result.serialnumber} onClick={() => handleCheckbox(result)}>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    checked={selectedCheckboxes.some((ch) => ch.serialnumber === result.serialnumber)}
                    onChange={() => {}}
                  />
                  <label className="ms-2">
                    {result.serialnumber} - {result.name}
                  </label>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>

      <div className="mb-10">
        <DropdownButton
          className="mt-2"
          id="tariff-picker"
          title={duration ? monthYear(duration) : `${t("settings.partner.book_service.select_tariff")}`}>
          {tariff.map((item, i) => (
            <Dropdown.Item key={item} eventKey="1" onClick={() => handleDurationChange(item)}>
              {monthYear(item)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      {/* <div style={styles.costBox}>
         <label>
          {t("settings.partner.book_service.cost_without_vat")}: {totalCostWithoutVAT.toFixed(2)} €
        </label>
        <label>
          {t("settings.partner.book_service.vat")} (19%): {totalVAT.toFixed(2)} €
        </label>
        <label>
          {t("settings.partner.book_service.total_cost")}: {totalCostWithVAT.toFixed(2)} €
        </label> 
      </div> */}

      <Button
        className="btn btn-primary-outline mt-4 mb-1"
        onClick={() => setShowModal(true)}
        disabled={selectedCheckboxes.length === 0}>
        {t("settings.partner.book_service.extend_service")}
      </Button>

      <ModalView
        title={t("settings.partner.book_service.extend_service")}
        buttonLoader={false}
        buttonTitle={t("settings.partner.request")}
        show={showModal}
        onHideModal={() => setShowModal(false)}
        onButtonClick={handleRequestAddTrackers}>
        <label>{t("settings.partner.book_service.request_extension_alert_refined")}</label>
      </ModalView>
    </div>
  )
}
