import { DevicesSelectTab, SearchDeviceType } from "@src/settings/components"
import React, { useEffect } from "react"
import { Tabs, Tab } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { FleetContactScreen } from "./FleetContactScreen"
import { FleetNotificationScreen } from "./FleetNotificationScreen"

const TABS = ["devices", "fleetContact", "fleetNotification"]

export const FleetsTabs: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab")

  useEffect(() => {
    if (!TABS.includes(tab)) setSearchParams({ tab: "devices" }, { replace: true })
  }, [])

  return (
    <Tabs activeKey={tab} onSelect={(key) => setSearchParams({ tab: key })} className="mb-3">
      <Tab eventKey={TABS[0]} title={t("sidebar.devices")}>
        <DevicesSelectTab type={SearchDeviceType.Fleet} />
      </Tab>
      <Tab eventKey={TABS[1]} title={t("settings.fleets.fleetContact")}>
        <FleetContactScreen />
      </Tab>
      <Tab eventKey={TABS[2]} title={t("settings.fleets.fleetNotification")}>
        <FleetNotificationScreen />
      </Tab>
    </Tabs>
  )
}
