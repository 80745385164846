import { callApi } from "@src/api/callApi"

type POST_DEVICE_POWER_SAVE_BODY = {
  device_serialnumbers: string[]
  interval: number
}

export const postDeviceBatchEnablePowersave = (partnerUuid: string, body: POST_DEVICE_POWER_SAVE_BODY) =>
  callApi<POST_DEVICE_POWER_SAVE_BODY, null>({
    method: "POST",
    path: `/partner/${partnerUuid}/device/batch_enable_powersaving`,
    body,
  })
