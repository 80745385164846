import React, { useCallback, useEffect, useRef, useState } from "react"
import { GoogleMap, Marker, Circle, MarkerClusterer, Polyline, Polygon } from "@react-google-maps/api"
import { getPositionHistory, POS_HISTORY_TYPE } from "@src/api/requests/getPositionHistory"
import { useLocalization } from "@src/localization"
import { Spinner } from "react-bootstrap"
import { map } from "@src/config"
import { colors } from "@src/constants"
import { usePartner } from "@src/partner"
import { useTranslation } from "react-i18next"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { PopupMarker } from "./components/PopupMarker"
import { StyleSheet } from "@src/types"
import { DATA_POINT_TYPE } from "@src/api"
import { checkIsCanTracker } from "@src/utils"
import { FENCE } from "@src/settings"
import tripEndCircle from "../../../../../assets/images/trip_end_circle.png"

export type COORDINATES_TYPE = {
  lat: string
  lng: string
}

type MapProps = {
  serialnumber?: string
  position: COORDINATES_TYPE
  showRoute: boolean
  showMultipleMarkers?: boolean
  datapoints?: COORDINATES_TYPE[]
  routeDatapoints?: DATA_POINT_TYPE[]
  loadDevices?: boolean
  mapDevices?: SLIM_DEVICE_TYPE[]
  fences?: FENCE[]
}

export const defaultCenter: COORDINATES_TYPE = {
  lat: "51.1657",
  lng: "10.4515",
}

const styles: StyleSheet = {
  verticalDivider: { backgroundColor: colors.gray, width: 1.5, height: 20 },
  divStyle: {
    background: "white",
  },
  spinner: {
    backgroundColor: colors.transparentBlack,
    position: "absolute",
    top: 0,
    height: map.height_medium,
    width: "100%",
  },
  spinnerInnerView: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.middleGray,
    height: "65px",
    paddingTop: 10,
    borderRadius: 8,
  },
  spinnerHeight: { height: map.height_medium },
}

const optionsMap = {
  circleMarkerIcon: {
    fillColor: colors.blue,
    fillOpacity: 1.0,
    strokeColor: colors.black,
    strokeWeight: 1.5,
    scale: 9,
  },
  polylineOptions: {
    strokeColor: colors.mustardYellow,
    strokeOpacity: 1.0,
    strokeWeight: 5,
    fillColor: colors.mustardYellow,
    fillOpacity: 1.0,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  },
  trackOptions: {
    strokeColor: "transparent",
    strokeOpacity: 0,
    strokeWeight: 5,
    fillColor: colors.black,
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 20,
    zIndex: 1,
  },
  mapOptions: {
    minZoom: 2,
    gestureHandling: "greedy",
    zoomControlOptions: { position: 9 },
    fullscreenControl: false,
  },
  polygonOption: {
    strokeColor: colors.orangeRed,
    strokeOpacity: 1.0,
    strokeWeight: 4,
    fillColor: colors.mustardYellow,
    fillOpacity: 0.35,
    geodesic: true,
    zIndex: 1,
  },
}

export const polygonColors = [
  colors.mustardyellow,
  colors.pink,
  colors.purple,
  colors.orangeRed,
  colors.maroon,
  colors.middleGreen,
  colors.blue,
  colors.lightBlue,
  colors.mildGreen,
  colors.superDarkGray,
]

export const loadMapsScript = (language: string, elementId: string, callback: any) => {
  const existingScript = document.getElementById(elementId)
  if (existingScript) {
    existingScript.parentNode.removeChild(existingScript)
  }
  const script = document.createElement("script")
  script.src = `https://maps.googleapis.com/maps/api/js?key=${map.key}&language=${language}`
  script.id = elementId
  document.body.appendChild(script)
  script.onload = () => {
    if (callback) callback()
  }
}

export const centerAlign = "d-flex align-self-center align-items-center justify-content-center"

export const Map: React.FC<MapProps> = ({
  serialnumber,
  position,
  showRoute,
  datapoints,
  routeDatapoints,
  showMultipleMarkers,
  loadDevices,
  mapDevices,
  fences,
}) => {
  const [positionHistoryData, setPositionHistoryData] = useState<POS_HISTORY_TYPE[]>()
  const { language } = useLocalization()
  const [loaded, setloaded] = useState<boolean>(false)
  const [center, setCenter] = useState<COORDINATES_TYPE>(position)
  const { brand } = usePartner()
  const [loadMarkers, setLoadMarkers] = useState<boolean>(false)
  const [polygons, setPolygons] = useState<any[]>()
  const { t } = useTranslation()
  const mapHeight = showMultipleMarkers ? map.height_medium : map.height_small
  var zoomConstant = position ? (showMultipleMarkers ? 5 : 15) : 6
  const containerStyle = {
    width: "100%",
    height: mapHeight,
  }

  useEffect(() => {
    // getPositionHistory(serialnumber).then(({ response, data }) => {
    //   if (response.ok) setPositionHistoryData(data)
    // })
  }, [])

  useEffect(() => {
    setloaded(false)
    setTimeout(() => {
      loadMapsScript(language, "googleMaps", () => {
        setloaded(true)
      })
    }, 300)
  }, [language])

  useEffect(() => {
    if (position) {
      setCenter(position)
    }
  }, [position])

  useEffect(() => {
    if (datapoints && datapoints.length != 0) {
      findCenter(datapoints)
    }
  }, [datapoints])

  useEffect(() => {
    setTimeout(() => {
      setLoadMarkers(loadDevices)
    }, 500)
  }, [loadDevices])

  useEffect(() => {
    if (fences) {
      let polygonFencePosts = fences.map((item) => {
        return typeof item.fencingposts == "string"
          ? fencingPostStrToArr(item.fencingposts)
          : item.fencingposts.map((item) => {
              return { lat: item[0], lng: item[1] }
            })
      })
      setPolygons(polygonFencePosts)
    }
  }, [fences])

  const fencingPostStrToArr = (fencingString: string) => {
    let fenceString = fencingString.slice(1, fencingString.length - 1)
    var coords = []
    fenceString
      .match(/[\d.]+/g)
      .map(Number)
      .forEach(function (a, i) {
        if (i % 2) {
          coords[coords.length - 1].lng = a
        } else {
          coords.push({ lat: a })
        }
      })

    return coords
  }

  const renderMarkerSpinner = () => {
    return (
      <div className={centerAlign} style={styles.spinnerHeight}>
        <div className={centerAlign} style={styles.spinner}>
          <div className={centerAlign} style={styles.spinnerInnerView}>
            <Spinner className="align-self-center" animation="border" size="sm" />
            <b className="m-2">{t("map.loader_text")}</b>
          </div>
        </div>
      </div>
    )
  }

  const renderSpinner = () => {
    return (
      <div className={centerAlign} style={{ height: mapHeight }}>
        <Spinner className="align-self-center" animation="border" size="sm" />
      </div>
    )
  }

  const renderDetailsMap = () => {
    return (
      <>
        {position && position.lat && position.lng && (
          <Marker
            position={{
              lat: Number(position.lat),
              lng: Number(position.lng),
            }}
            onLoad={(marker) => onLoadMarker(marker)}
          />
        )}
        {positionHistoryData &&
          positionHistoryData.map((positionItem, idx) => (
            <Circle
              key={idx}
              center={{
                lat: Number(positionItem.lat),
                lng: Number(positionItem.lng),
              }}
              options={optionsMap.trackOptions}
            />
          ))}
        {polygons && polygons.map((item, index) => renderPolygons(item, index))}
      </>
    )
  }

  const renderPolygons = (path: any[], i: number) => {
    return <Polygon paths={path} options={{ ...optionsMap.polygonOption, fillColor: polygonColors[i] }} />
  }

  const markerIconSVG = (markerColor: string) => {
    return Object.assign({
      path: map.markerSVGPath,
      fillColor: markerColor,
      fillOpacity: 0.85,
      strokeColor: colors.white,
      strokeWeight: 1,
      scale: 2.5,
      anchor: new window.google.maps.Point(4, 15),
    })
  }

  const onLoadMarker = (marker: google.maps.Marker) => {
    marker.setIcon(markerIconSVG(brand.color))
    setLoadMarkers(false)
  }

  const renderClusterMap = () => {
    return (
      <MarkerClusterer>
        {(clusterer) => (
          <>
            {datapoints &&
              datapoints?.map((point: COORDINATES_TYPE, i) => (
                <PopupMarker datapoint={point} device={mapDevices && mapDevices[i]} clusterer={clusterer} />
              ))}
            {loadMarkers && showMultipleMarkers && renderMarkerSpinner()}
          </>
        )}
      </MarkerClusterer>
    )
  }

  const renderRouteMap = () => {
    return (
      <>
        {datapoints &&
          datapoints?.map((point: COORDINATES_TYPE, i) => (
            <> {mapDevices.length !== 0 && <PopupMarker datapoint={point} device={mapDevices[i]} />} </>
          ))}
        {loadMarkers && showMultipleMarkers && renderMarkerSpinner()}
      </>
    )
  }

  const getStrokeColor = (trip_datapoints: DATA_POINT_TYPE[]) => {
    let isLastDatapointPumpActive = false
    let colorsArray: string[] = []

    if (checkIsCanTracker(trip_datapoints)) {
      trip_datapoints.forEach((datapoint: DATA_POINT_TYPE) => {
        if (datapoint.pump_active_flag) {
          isLastDatapointPumpActive = true
          colorsArray.push(colors.mustardYellow)
        } else if (datapoint?.pump_active_flag === undefined || datapoint?.pump_active_flag === null) {
          colorsArray.push(isLastDatapointPumpActive ? colors.mustardYellow : colors.darkViolet)
        } else {
          isLastDatapointPumpActive = false
          colorsArray.push(colors.darkViolet)
        }
      })
    } else {
      trip_datapoints.forEach((datapoint: DATA_POINT_TYPE) => {
        // It is not a can tracker. We are colouring by battery present indicating the device is turned on/off.
        colorsArray.push(datapoint?.battery_present ? colors.mustardYellow : colors.darkViolet)
      })
    }

    return colorsArray
  }

  const renderPolyline = () => {
    if (!routeDatapoints || routeDatapoints.length === 0) {
      return null
    }

    const path = routeDatapoints.map((point) => ({
      lat: parseFloat(point.lat),
      lng: parseFloat(point.lng),
    }))

    const strokeColors = getStrokeColor(routeDatapoints)

    const segments = path.slice(1).map((point, index) => ({
      path: [path[index], point],
      strokeColor: strokeColors[index],
    }))

    const markicon = {
      options: {
        path: google.maps.SymbolPath.CIRCLE,
        ...optionsMap.circleMarkerIcon,
      },
    }
    const strokeColor = getStrokeColor(routeDatapoints || [])

    const polylineUpdatedOptions = {
      strokeColor,
      strokeOpacity: 1.0,
      strokeWeight: 5,
      fillColor: colors.mustardYellow,
      fillOpacity: 1.0,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 30000,
      zIndex: 1,
    }

    return (
      <>
        {segments.map((segment, index) => (
          <Polyline
            key={index}
            path={segment.path}
            options={{
              ...optionsMap.polylineOptions,
              strokeColor: segment.strokeColor,
            }}
          />
        ))}
        {routeDatapoints.length > 0 && (
          <>
            <Marker
              icon={markicon.options}
              position={{
                lat: Number(routeDatapoints[0].lat),
                lng: Number(routeDatapoints[0].lng),
              }}
            />
            <Marker
              icon={{
                url: tripEndCircle,
                scaledSize: new window.google.maps.Size(20, 20),
                anchor: new window.google.maps.Point(15, 10),
              }}
              position={{
                lat: Number(routeDatapoints[routeDatapoints.length - 1].lat),
                lng: Number(routeDatapoints[routeDatapoints.length - 1].lng),
              }}
            />
          </>
        )}
      </>
    )
  }

  const findCenter = (coords: any[]) => {
    let lat = 0
    let lng = 0

    for (let i = 0; i < coords.length; ++i) {
      lat += Number(coords[i].lat)
      lng += Number(coords[i].lng)
    }

    lat /= coords.length
    lng /= coords.length
    setCenter({ lat: `${lat}`, lng: `${lng}` })
  }

  return (
    <>
      {loaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: center ? Number(center.lat) : Number(defaultCenter.lat),
            lng: center ? Number(center.lng) : Number(defaultCenter.lng),
          }}
          options={optionsMap.mapOptions}
          zoom={zoomConstant}
          id="googleMaps">
          {showRoute && renderPolyline()}
          {!showMultipleMarkers && !showRoute && renderDetailsMap()}
          {showMultipleMarkers && datapoints && datapoints.length > 100 ? renderClusterMap() : renderRouteMap()}
        </GoogleMap>
      ) : (
        renderSpinner()
      )}
    </>
  )
}
