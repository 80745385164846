import { callApi } from "../callApi"

export type DELETE_ROLES_BODY = {
  user_ids: number[]
  role: string
}

export const deletePartnerUuidRoles = async (partnerUuid: string, body: DELETE_ROLES_BODY) => {
  return callApi<DELETE_ROLES_BODY, null>({
    method: "DELETE",
    path: `/partner/${partnerUuid}/roles`,
    body,
  })
}
