import { callApi } from "@src/api/callApi"
import { DEVICE_TYPE } from "@src/devices/types"

type PUT_DEVICE_LOCK_RESPONSE = {
  data: DEVICE_TYPE
}

export const putDeviceSerialnumberLock = (partnerUuid: string, serialnumber: string) =>
  callApi<null, PUT_DEVICE_LOCK_RESPONSE>({
    method: "PUT",
    path: `/partner/${partnerUuid}/device/${serialnumber}/lock`,
  })
