import React, { createContext, useState, useContext } from "react"
import { load, usePersistedState } from "@src/utils"
import { deleteToken, postToken } from "@src/api"

const DEFAULT_TOKEN = ""

type LOGIN_RETURN = {
  hasToken: boolean
  token: string
  status: number
}

type AUTHENTICATION_CONTEXT = {
  token: string
  tokenHasBeenLoaded: boolean
  isAuthenticated: boolean
  isAdmin: boolean
  isManager: boolean
  isInvite: boolean
  loading: boolean
  login: (email: string, password: string) => Promise<LOGIN_RETURN>
  logout: () => void
  userId: number
  updateUserRoleToManager: (val: boolean) => void
  updateUserRoleToAdmin: (val: boolean) => void
  updateInvite: (val: boolean) => void
  userEmail: string
}

export const DEFAULT_AUTHENTICATION_CONTEXT = {
  token: DEFAULT_TOKEN,
  tokenHasBeenLoaded: false,
  isAuthenticated: false,
  isAdmin: false,
  isManager: false,
  isInvite: false,
  loading: false,
  partners: [],
  partner: null,
  login: async () => ({ hasToken: false, token: "" }),
  logout: () => {},
  updatePartners: () => {},
  changePartner: () => {},
  userId: 0,
  updateUserRoleToManager: () => {},
  updateUserRoleToAdmin: () => {},
  updateInvite: () => {},
  userEmail: "",
}

export const AuthenticationContext = createContext<AUTHENTICATION_CONTEXT>(DEFAULT_AUTHENTICATION_CONTEXT)

export const TOKEN = "@token"
export const loadToken = (): string | null => load<string>(TOKEN)

type AuthenticationProviderProps = {
  children: React.ReactNode
}
export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [token, setToken, tokenHasBeenLoaded] = usePersistedState<string>(TOKEN, DEFAULT_TOKEN)
  const [userId, setUserId] = usePersistedState<number>("user_id", 0)
  const [isAdmin, setIsAdmin] = usePersistedState<boolean>("user_role_admin", false)
  const [isManager, setIsManager] = usePersistedState<boolean>("user_role_manager", false)
  const [isInvite, setIsInvite] = useState<boolean>(false)
  const [userEmail, setUserEmail] = usePersistedState<string>("user_email", "")

  const login = async (email: string, password: string): Promise<LOGIN_RETURN> => {
    setLoading(true)
    return await postToken({ email, password }).then(({ data, response }) => {
      setLoading(false)
      if (response.ok && data?.user_token?.access_token) {
        setToken(data.user_token.access_token)
        setUserId(data.user_token.user.id)
        setUserEmail(data.user_token.user.email)
        return {
          token: data.user_token.access_token,
          hasToken: true,
          status: 200,
        }
      }
      return {
        token: "",
        hasToken: false,
        status: data.errors?.user ? response.status : 443,
      }
    })
  }

  const updateUserRoleToAdmin = (val: boolean) => {
    setIsAdmin(val)
    val && updateUserRoleToManager(false)
  }

  const updateUserRoleToManager = (val: boolean) => {
    setIsManager(val)
    val && updateUserRoleToAdmin(false)
  }

  const updateInvite = (val: boolean) => {
    setIsInvite(val)
  }

  const logout = () => {
    setLoading(true)
    setIsManager(false)
    setIsAdmin(false)
    deleteToken().then(() => {
      setLoading(false)
      setToken("")
    })
  }

  const context = {
    token,
    tokenHasBeenLoaded,
    isAuthenticated: Boolean(token),
    isAdmin,
    isManager,
    isInvite,
    loading,
    login,
    logout,
    userId,
    updateUserRoleToAdmin,
    updateUserRoleToManager,
    updateInvite,
    userEmail,
  }

  return <AuthenticationContext.Provider value={context}>{children}</AuthenticationContext.Provider>
}

export const useAuthentication = () => useContext(AuthenticationContext)
