import { callApi } from "@src/api/callApi"
import { PARTNER } from "@src/partner"

type PUT_PARTNER_BODY = {
  partner: PARTNER
}

type PUT_PARTNER_RESPONSE = PARTNER

export const putPartnerUuid = (partnerUuid: string, body: PUT_PARTNER_BODY) =>
  callApi<PUT_PARTNER_BODY, PUT_PARTNER_RESPONSE>({
    method: "PUT",
    path: `/partner/${partnerUuid}`,
    body,
  })
