export const fencingArrtoCoordinates = (fencingPost: any[]) => {
  return fencingPost.map((item) => {
    return { lat: item[0], lng: item[1] }
  })
}

export const fencingPostStrToArr = (fencingString: string) => {
  let fenceString = fencingString.slice(1, fencingString.length - 1)
  var coords = []
  fenceString
    .match(/[\d.]+/g)
    .map(Number)
    .forEach(function (a, i) {
      if (i % 2) {
        coords[coords.length - 1].lng = a
      } else {
        coords.push({ lat: a })
      }
    })
  return coords
}
