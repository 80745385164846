import { callApi } from "../callApi"
import { PUT_DEVICES_FENCE_BODY } from "./putFleetsUuidFencesIdAddDevices"

export const deleteFleetsUuidFencesIdRemoveDevices = async (
  partnerUuid: string,
  fleetUuid: string,
  fenceId: number,
  body: PUT_DEVICES_FENCE_BODY,
) =>
  callApi<PUT_DEVICES_FENCE_BODY, null>({
    method: "PUT",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/fences/${fenceId}/remove_devices`,
    body,
  })
