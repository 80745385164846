import React, { useState } from "react"
import { Button, Row, Spinner } from "react-bootstrap"
import { BlockPicker } from "react-color"
import * as Icon from "react-bootstrap-icons"
import { colors } from "@src/constants"
import { StyleSheet } from "@src/types"

type ColorPickerProps = {
  color: string
  onChangeColor: (color: any) => void
  loading: boolean
}

const presetColors = [
  colors.darkViolet,
  colors.pink,
  colors.orangeRed,
  colors.mustardyellow,
  colors.purple,
  colors.black,
  colors.maroon,
  colors.middleGreen,
  colors.blue,
  colors.lightBlue,
  colors.mildGreen,
  colors.superDarkGray,
  colors.lightGray,
  colors.white,
]

export const ColorPicker: React.FC<ColorPickerProps> = ({ onChangeColor, color, loading }) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false)
  const [colorValue, setColorValue] = useState<string>(color)

  const styles: StyleSheet = {
    colorButton: {
      width: "25px",
      height: "25px",
      backgroundColor: colorValue,
      borderRadius: 4,
      borderColor: colorValue,
    },
    whiteBaseView: { backgroundColor: "white", borderRadius: 4 },
    tickButton: { width: "40px", height: "40px" },
    absoluteView: { position: "absolute", zIndex: 1 },
  }

  return (
    <div>
      <Row>
        <div className="d-flex align-items-center">
          <Button className="ms-1" style={styles.colorButton} onClick={() => setShowColorPicker(true)} />
          <p className="small ms-2 mt-3">{colorValue}</p>
          {loading && <Spinner className="ms-2" animation="border" size="sm" />}
        </div>
      </Row>
      {showColorPicker && (
        <div
          style={styles.absoluteView}
          onMouseLeave={() => {
            setShowColorPicker(false)
            if (color !== colorValue) onChangeColor(colorValue)
          }}>
          <Row className="shadow-lg" style={styles.whiteBaseView}>
            <BlockPicker onChange={(color) => setColorValue(color.hex)} color={colorValue} colors={presetColors} />
            <Button
              size="sm"
              onClick={() => {
                setShowColorPicker(false)
                if (color !== colorValue) onChangeColor(colorValue)
              }}
              className="mt-2 ms-1 me-2"
              style={styles.tickButton}
              variant="outline-dark">
              <Icon.Check size={20} />
            </Button>
          </Row>
        </div>
      )}
    </div>
  )
}
