import { callApi } from "../callApi"

export type GET_ILOCKIT_LOCKSTATUS_RESPONSE = {
  bluetoothKey: string
  bluetoothName: string
  createdAt: string
  id: number
  trackerSerialNumber: string
  updatedAt: string
}

export const getiLockitLockStatus = async (partnerUuid: string, serialnumber: string) =>
  callApi<null, GET_ILOCKIT_LOCKSTATUS_RESPONSE>({
    method: "GET",
    path: `/gsm/${serialnumber}`,
    endpointType: "ilockit",
    partnerUuid,
  })
