import React, { useEffect, useRef, useState } from "react"
import { Row, Button, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { PARTNER, usePartner } from "@src/partner"
import { putPartnerUuid } from "@src/api"
import { ColorPicker } from "../components"
import { cloudinary } from "@src/config"

// Define props type
type PartnerDetailProps = {
  isCreatePartner?: boolean
  onColorChange?: (color: string) => void
  onLogoChange?: (logoUrl: string) => void
}

export const PartnerDetail: React.FC<PartnerDetailProps> = ({ isCreatePartner, onColorChange, onLogoChange }) => {
  const { partner, updatePartners, changePartner } = usePartner()
  const [colorLoading, setColorLoading] = useState<boolean>(false)
  const [image, setImage] = useState<any>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const [logoUrl, setLogoUrl] = useState<string>("")
  const { t } = useTranslation()
  const imgBorderRadius = { borderRadius: 10 }

  useEffect(() => {
    // Initialize logoUrl if partner data exists
    if (partner) {
      setLogoUrl(partner.logo_url)
    }
  }, [partner])

  const handleUpload = () => {
    inputRef.current?.click()
  }

  const uploadImages = async (imageData: File) => {
    if (imageData) {
      setImageLoading(true)
      const data = new FormData()
      data.append("file", imageData)
      data.append("upload_preset", cloudinary.upload_preset)
      data.append("cloud_name", cloudinary.name)

      try {
        const response = await fetch(`${cloudinary.uploadEndPoint + cloudinary.name}/image/upload`, {
          method: "post",
          body: data,
        })

        const resData = await response.json()

        if (resData && response.ok) {
          setLogoUrl(resData.url)
          onLogoChange?.(resData.url)

          if (partner) {
            const updatedPartnerData = { ...partner, logo_url: resData.url }
            updatePartnerDetails(updatedPartnerData)
          }
        }
      } catch (error) {
        console.error("Image upload failed:", error)
      } finally {
        setImageLoading(false)
      }
    }
  }

  const updatePartnerDetails = (updatedPartnerData: PARTNER) => {
    putPartnerUuid(updatedPartnerData.uuid, { partner: updatedPartnerData })
      .then(({ response }) => {
        if (response.ok) {
          updatePartners()
          changePartner(updatedPartnerData.uuid)
        }
      })
      .catch((error) => {
        console.error("Failed to update partner details:", error)
      })
      .finally(() => {
        setColorLoading(false)
      })
  }

  const handleColorChange = (color: string) => {
    setColorLoading(true)
    onColorChange?.(color)

    // If partner exists, update their color
    if (partner) {
      const updatedPartnerData = { ...partner, color: color.replace("#", "") }
      updatePartnerDetails(updatedPartnerData)
    }

    setColorLoading(false)
  }

  return (
    <>
      <Row>
        <div className="d-flex">
          <b className="small">{t("settings.partner.brandColor")}</b>
        </div>
        <ColorPicker
          onChangeColor={handleColorChange}
          loading={colorLoading}
          color={
            partner && partner.color && !partner.color.includes("#") ? `#${partner.color}` : partner?.color || "#000000" // Default color if no partner color
          }
        />
      </Row>
      <Row>
        <div className="mt-2 ">
          <b className="small">{t("settings.partner.logo")}</b>
          <Row>
            <div>
              {imageLoading ? (
                <Spinner className="ms-3 mt-3" animation="border" size="sm" />
              ) : (
                logoUrl && (
                  <img className="mt-2 me-3" src={logoUrl} height="70" style={imgBorderRadius} alt="Partner Logo" />
                )
              )}
              <input
                ref={inputRef}
                className="d-none"
                type="file"
                onChange={(e) => {
                  const selectedFile = e.target.files?.[0]
                  if (selectedFile) {
                    setImage(selectedFile)
                    uploadImages(selectedFile)
                  }
                }}
              />
              <Button size="sm" onClick={handleUpload} className="mt-2 btn btn-primary-outline">
                {t("settings.partner.browse")}
              </Button>
            </div>
          </Row>
          <Row>
            <label className="mt-2">{image && image.name}</label>
          </Row>
        </div>
      </Row>
      {!partner && !isCreatePartner && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      )}
    </>
  )
}
