import { PARTNER } from "@src/partner"
import { callApi } from "../callApi"
import { USER_ROLE } from "./getPartnerUuidRoles"

export type INVITES = {
  role: string
  email: string
  fleet_operator: PARTNER
  list_of_fleet_ids: number[]
  user: USER_ROLE
}

export const getPartnerUuidInvitations = async (partnerUuid: string) => {
  return callApi<null, INVITES[]>({ method: "GET", path: `/partner/${partnerUuid}/invitations` })
}
