import { getPartnerUuidFleets } from "@src/api"
import { usePartner } from "@src/partner"
import { usePersistedState } from "@src/utils"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { config } from "@src/constants"
import { FLEETS } from "./types"

type FLEET_CONTEXT = {
  fleet: FLEETS
  fleets: FLEETS[]
  updateFleets: () => void
  updateFleetUuid: (id: string) => void
}

const DEFAULT_FLEET_CONTEXT = {
  fleet: null,
  fleets: [],
  updateFleets: () => {},
  updateFleetUuid: () => {},
}

export const FleetContext = createContext<FLEET_CONTEXT>(DEFAULT_FLEET_CONTEXT)

export const FleetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [fleets, setFleets] = usePersistedState<FLEETS[]>("@fleetsList", [])
  const { partner } = usePartner()
  const navigate = useNavigate()
  const [fleetUuid, setFleetUuid] = usePersistedState<string>("@fleetUuid", "")
  const findFleet = (uuid: string, fleetsList = fleets) => fleetsList.find((fleet) => fleet.uuid === uuid)

  let fleet: FLEETS = null
  try {
    fleet = findFleet(fleetUuid)
  } catch (error) {
    // throw error
  }

  useEffect(() => {
    if (partner) {
      setTimeout(() => {
        updateFleets()
      }, 0)
    } else {
      setFleets([])
    }
  }, [partner])

  const updateFleets = (): Promise<FLEETS[]> => {
    return getPartnerUuidFleets(partner.uuid, {
      page: 0,
      per_page: config.itemsPerPage,
    }).then(({ response, data }) => {
      if (response.ok) {
        setFleets(data.fleets)
        return data.fleets
      } else if (response.status == 401) {
        console.log("error", response)
      } else {
        navigate(`/${partner.uuid}`)
      }
    })
  }

  const updateFleetUuid = (uuid?: string) => {
    if (!uuid) setFleetUuid("")
    else if (uuid !== fleetUuid && partner) {
      fleet = findFleet(fleetUuid)
      setFleetUuid(uuid)
    }
  }

  const context = {
    fleet,
    fleets,
    updateFleets,
    updateFleetUuid,
  }

  return <FleetContext.Provider value={context}>{children}</FleetContext.Provider>
}

export const useFleets = () => useContext(FleetContext)
