import { Pagination } from "react-bootstrap"

type PaginationProps = {
  activePage: number
  pagesCount: number
  onChangePage: (page: number) => void
}

export const PaginationItem: React.FC<PaginationProps> = ({ activePage, pagesCount, onChangePage }) => {
  const items = [<Pagination.First key={"first"} onClick={() => onChangePage(0)} />]

  let previousPageIsEllipsis = false

  for (let i = 0; i < pagesCount; i++) {
    if (
      i == 0 ||
      i == activePage - 2 ||
      i == activePage - 1 ||
      i == activePage + 1 ||
      i == activePage + 2 ||
      i == activePage ||
      i == pagesCount - 1
    ) {
      items.push(
        <Pagination.Item key={i} active={i === activePage} onClick={() => onChangePage(i)}>
          {i + 1}
        </Pagination.Item>,
      )
      previousPageIsEllipsis = false
    } else if (!previousPageIsEllipsis) {
      items.push(<Pagination.Ellipsis disabled />)
      previousPageIsEllipsis = true
    }
  }
  items.push(<Pagination.Last key={"last"} onClick={() => onChangePage(pagesCount - 1)} />)

  return <Pagination>{items}</Pagination>
}
