import React, { useEffect, useState } from "react"
import { Row, Col, Container, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { colors, config } from "@src/constants"
import { useParams } from "react-router-dom"
import { EnvelopeCheck } from "react-bootstrap-icons"
import "./verify.css"
import $ from "jquery"
import { getUsersConfirmationToken } from "@src/api"
import { useNavigate } from "react-router-dom"
import { useAuthentication } from "../useAuthentication"

const COLUMN = { marginTop: "10rem", borderRadius: 10 }

const styles = {
  divider: {
    backgroundColor: colors.gray,
    height: 1,
    width: "100%",
  },
}

export const VerifyUser: React.FC = () => {
  const { t } = useTranslation()
  const { accessTokenId } = useParams()
  const [showError, setShowError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { logout } = useAuthentication()

  useEffect(() => {
    logout()
    setLoading(true)
    getUsersConfirmationToken(accessTokenId).then(({ response, data }) => {
      setLoading(false)
      if (response.ok) {
        $(".circle-loader").toggleClass("load-complete")
        $(".checkmark").toggle()
        setShowError(false)
      } else {
        setShowError(true)
      }
    })
  }, [])

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="10" lg="8" className="shadow-lg bg-body p-4" style={COLUMN}>
          <div className="d-flex align-items-center justify-content-center m-2 flex-column">
            <EnvelopeCheck size={35} color={colors.black} />
            <b className="d-flex  mt-2">{t("verify_user.confirm_account")}</b>
            {loading ? (
              <div className="circle-loader mt-3">
                <div className="checkmark draw"></div>
              </div>
            ) : (
              <div className="mt-3">
                <label>
                  {showError ? t("verify_user.already_confirmed_email") : t("verify_user.verify_msg")}{" "}
                  {t("verify_user.please")}{" "}
                  <b className="text-decoration-underline pointer" onClick={() => navigate("/login")}>
                    {t("verify_user.click_here")}
                  </b>{" "}
                  {t("verify_user.to_login")}
                </label>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
