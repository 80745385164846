import { loadToken } from "@src/auth"
import { api } from "@src/config"
import { putSentryLog } from "./putSentryLog"

type BUILD_HEADERS = (partnerUuid: string | null) => {
  Accept: "application/json"
  "Content-Type": "application/json"
  Authorization?: string
}

const buildHeaders: BUILD_HEADERS = (partnerUuid) => {
  const token = loadToken()
  console.log("build headers", token)
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(token && { Authorization: `Token token=${token}` }),
    ...(partnerUuid && { partner_uuid: partnerUuid }),
  }
}

type PARAMS<BODY_TYPE> = {
  method: "POST" | "PUT" | "DELETE" | "GET" | "PATCH"
  path: string
  body?: BODY_TYPE
  url?: string
  endpointType?: "api" | "ilockit"
  partnerUuid?: string
}

type RESPONSE<T> = {
  response: {
    ok: boolean
    status: number
  }
  data?: T
}

export const callApi = async <BODY_TYPE, RESPONSE_TYPE>({
  method,
  path,
  body,
  endpointType = "api",
  partnerUuid = null,
}: PARAMS<BODY_TYPE>): Promise<RESPONSE<RESPONSE_TYPE>> =>
  fetch((endpointType === "ilockit" ? api.iLockItUrl : api.baseUrl) + path, {
    method,
    headers: buildHeaders(partnerUuid),
    ...(body && { body: JSON.stringify(body) }),
  })
    .then((response) => {
      try {
        return response.json().then((data) => {
          if (!response.ok) {
            const token = loadToken()
            const logObject = {
              method: method,
              userText: token,
              body: body && JSON.stringify(body),
              url: api.baseUrl + path,
              status: response.status,
              statusText: response.statusText,
            }
            putSentryLog(logObject)
          }
          return { response, data }
        })
      } catch (error) {
        console.error("error", api.baseUrl + path, error)
        return { response }
      }
    })
    .catch((error) => {
      console.error("error", api.baseUrl + path, error)
      return {
        response: {
          ok: false,
          status: 500,
        },
      }
    })
