import { callApi } from "../callApi"

export type DATA_POINT_TYPE = {
  battery: number
  battery_present: boolean
  created_at: string
  lat: string
  lng: string
  sampled_at: string
  pet_activity: string
  working_width_value: number
  working_width_unit: string
  flow_rate_value: number
  flow_rate_unit: string
  grit_value: number
  control_active_flag: boolean
  pump_active_flag: boolean
}

export type GET_TRIP_SHOW_RESPONSE = {
  average_speed: number
  begin_notification_processed_at: string
  created_at: string
  datapoints: DATA_POINT_TYPE[]
  device_id: number
  distance: number
  duration_s: number
  finished: boolean
  id: number
  pseudo_co2_saving: number
  trip_end: string
  trip_start: string
  updated_at: string
  average_speed_unit: string
  distance_unit: string
  pseudo_co2_saving_unit: string
}

export const getPartnerUuidTripsId = (partnerUuid: string, trip_id: number) => {
  return callApi<null, GET_TRIP_SHOW_RESPONSE>({ method: "GET", path: `/partner/${partnerUuid}/trips/${trip_id}` })
}
