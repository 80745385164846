import React from "react"
import { Link } from "react-router-dom"
import { Badge } from "react-bootstrap"
import packageJson from "./../../package.json"
import { useTranslation } from "react-i18next"

const Separator: React.FC = () => <span className="text-white"> | </span>

const Footer: React.FC = () => {
  const { t } = useTranslation()
  return (
    <footer id="footer">
      <div className="container">
        <ul>
          <Link to="/legal/privacy">{t("login_screen.privacy")}</Link>
          <Separator />
          <Link to="/legal/imprint">{t("login_screen.imprint")}</Link>
          <Separator />
          <Badge bg="secondary">v{packageJson.version}</Badge>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
