import { callApi } from "@src/api/callApi"
import { DEVICE_TYPE } from "@src/devices/types"

type PATCH_DEVICE_BODY = DEVICE_TYPE

type PATCH_DEVICE_RESPONSE = {
  success: boolean
  device: DEVICE_TYPE // TO DO: The backend device response is incomplete
}

export const patchDeviceSerialNumber = (partnerUuid: string, body: PATCH_DEVICE_BODY) =>
  callApi<PATCH_DEVICE_BODY, PATCH_DEVICE_RESPONSE>({
    method: "PATCH",
    path: `/partner/${partnerUuid}/device/${body.serialnumber}`,
    body,
  })
