import { callApi } from "../callApi"

type POST_INVITE_BODY = {
  email: string
  role: string
}

export const postPartnerUuidInvitations = async (partnerUuid: string, body: POST_INVITE_BODY) => {
  return callApi<POST_INVITE_BODY, null>({ method: "POST", path: `/partner/${partnerUuid}/invitations`, body })
}
