import { callApi } from "../callApi"
import { FLEETS } from "@src/settings"

type GET_FLEETS_PARAMS = {
  page?: number
  per_page?: number
  search_string?: string
  sort_by?: string
  sort_desc?: boolean
}

export type GET_FLEETS_RESPONSE = {
  fleets: FLEETS[]
  meta: {
    page: number
    per_page: number
    total: number
    search_string: string
    sort_by: string
    sort_desc: string
  }
}

export const getPartnerUuidFleets = async (partnerUuid: string, params?: GET_FLEETS_PARAMS) => {
  const queryString = Object.keys(params || {})
    .map((key) => key + "=" + params[key])
    .join("&")
  return callApi<null, GET_FLEETS_RESPONSE>({ method: "GET", path: `/partner/${partnerUuid}/fleets?${queryString}` })
}
