import React, { useEffect, useState, useCallback, useRef } from "react"
import { FleetDropDown, InputField, ModalView } from "@src/components"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { defaultCenter, Map } from "@src/device/devicedetail/tabs/components"
import { GeoFenceMap } from "./components/GeoFenceComponent"
import { Button, Spinner, Col, Form } from "react-bootstrap"
import { getDevice, postFleetsUuidFences, putFleetsUuidFencesIdAddDevices } from "@src/api"
import { usePartner } from "@src/partner"
import { useNavigate, useParams } from "react-router-dom"
import { useFleets } from "@src/settings"
import { useFence } from "../useFence"
import { SearchCityFence } from "./components"
import { fencingArrtoCoordinates, fencingPostStrToArr } from "@src/utils/coordinates"

type Coordinate = {
  lat: number
  lng: number
}

export const FenceDetailTab: React.FC = () => {
  const { t } = useTranslation()
  const { partner } = usePartner()
  const { fleet, updateFleetUuid } = useFleets()
  const { fence, loadFenceById, patchFence, loadFences } = useFence()
  const [fencingpost, setFencingpost] = useState([])
  const [fenceName, setFenceName] = useState<string>("")
  const { fenceUuid } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [successMsg, setSuccessMsg] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>("")
  const [initialLoad, setInitialLoad] = useState<boolean>(false)
  const navigate = useNavigate()
  const [mapCenter, setMapCenter] = useState<Coordinate>({ lat: 51.1657, lng: 10.4515 })
  const [mapZoom, setMapZoom] = useState<number>(5)
  const [geofence, setGeofence] = useState<Coordinate[]>([])
  const searchCityFenceRef = useRef<any>(null)

  useEffect(() => {
    if (fenceUuid) {
      setInitialLoad(true)
      loadFenceById(parseInt(fenceUuid))
    }
  }, [fenceUuid])

  useEffect(() => {
    if (fence) {
      setInitialLoad(false)
      setFenceName(fence.name)
      const convertedPosts =
        typeof fence.fencingposts == "string"
          ? fencingPostStrToArr(fence.fencingposts)
          : fencingArrtoCoordinates(fence.fencingposts)
      setGeofence(convertedPosts)
      setFencingpost(convertedPosts)
      if (convertedPosts.length > 0) {
        findCenter(convertedPosts)
        if (window.google && window.google.maps && window.google.maps.geometry) {
          setMapZoom(calculateZoomFromArea(google.maps.geometry.spherical.computeArea(convertedPosts))) // Adjust zoom level when loading an existing fence
        }
      }
    }
  }, [fence])

  const createFence = async () => {
    if (fenceName == "") {
      setAlertMsg(t("settings.fence.validate.name"))
    } else if (!fleet) {
      setAlertMsg(t("settings.fence.validate.fleet_select"))
    } else if (fencingpost.length < 3) {
      setAlertMsg(t("settings.fence.validate.fencing_post"))
    } else {
      setLoading(true)
      const stringPaths = fencingpost.map((latLng) => {
        return `[${latLng.lat}, ${latLng.lng}]`
      })
      let body = { fence: { kind: "classic", fencingposts: `[${stringPaths}]`, name: fenceName } }
      if (fenceUuid) {
        let response = await patchFence(body)
        if (response.ok) {
          setSuccessMsg(true)
          setLoading(false)
        }
      } else {
        postFleetsUuidFences(partner.uuid, fleet.uuid, body).then(({ response, data }) => {
          setSuccessMsg(true)
          loadFences()
        })
      }
    }
    setShowModal(true)
  }

  const onInputPersist = (field: string) => (text: string) => {
    setFenceName(text)
  }

  const spinLoader = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" />
      </div>
    )
  }

  const handleMapChange = useCallback((newCenter: Coordinate, newZoom: number) => {
    setMapCenter(newCenter)
    setMapZoom(newZoom)
  }, [])

  const handleSearchResult = useCallback((coords: Coordinate[]) => {
    setGeofence(coords)
    if (coords.length > 0) {
      findCenter(coords)

      setMapZoom(calculateZoomFromArea(google.maps.geometry.spherical.computeArea(coords)))
    }
  }, [])

  const clearSearch = () => {
    if (searchCityFenceRef.current) {
      searchCityFenceRef.current.clearSearch()
    }
  }

  const calculateZoomFromArea = (area) => {
    const ZOOM_LEVELS = [
      { area: 1, zoom: 20 }, // For very small areas
      { area: 10, zoom: 19 },
      { area: 100, zoom: 18 },
      { area: 1000, zoom: 17 },
      { area: 10000, zoom: 16 },
      { area: 50000, zoom: 15 },
      { area: 100000, zoom: 14 },
      { area: 1000000, zoom: 13 },
      { area: 10000000, zoom: 12 },
      { area: 100000000, zoom: 11 },
      { area: 1000000000, zoom: 10 },
      { area: 10000000000, zoom: 7.5 },
      { area: 100000000000, zoom: 7 },
    ]

    // Find the appropriate zoom level for the area
    for (let i = 0; i < ZOOM_LEVELS.length; i++) {
      if (area < ZOOM_LEVELS[i].area) {
        return ZOOM_LEVELS[i].zoom
      }
    }
    return 5 // Default zoom for very large areas
  }

  const findCenter = (coords: any[]) => {
    let lat = 0
    let lng = 0

    for (let i = 0; i < coords.length; ++i) {
      lat += Number(coords[i].lat)
      lng += Number(coords[i].lng)
    }

    lat /= coords.length
    lng /= coords.length
    setMapCenter({ lat: lat, lng: lng })
  }

  return (
    <div>
      {initialLoad ? (
        spinLoader()
      ) : (
        <div className="d-flex flex-column-reverse flex-lg-row bg-highlight">
          <Col className="me-4">
            <GeoFenceMap
              center={mapCenter}
              zoom={mapZoom}
              geofence={geofence}
              onGeofenceChange={(fence) => {
                setGeofence(fence)
                setFencingpost(fence)
              }}
              onMapChange={handleMapChange}
              editable={true}
            />
            <Form.Text>{t("settings.fence.double_click")}</Form.Text>
          </Col>
          <Col xs={12} md={6} lg={4}>
            {!fenceUuid && (
              <>
                <InputField
                  label={t("settings.fence.geofence_name")}
                  value={fenceName}
                  onPersist={onInputPersist("name")}
                  loading={false}
                  onChangeText={() => onInputPersist("name")}
                />

                <b>{t("settings.fleets.select_fleet")}</b>
                <div className="d-flex mt-2 mb-3">
                  <FleetDropDown
                    withoutAllOption
                    fleetUpdate={false}
                    onClick={(fleet) => {
                      updateFleetUuid(fleet.uuid)
                    }}
                  />
                </div>
              </>
            )}
            <Form.Text>{t("settings.fence.click_fence")}</Form.Text>
            <SearchCityFence
              ref={searchCityFenceRef}
              onSearchResult={(coords) => {
                setFencingpost(coords)
                handleSearchResult(coords)
              }}
            />
            <div className="d-flex mt-3">
              <Button
                className="me-2"
                variant="secondary"
                onClick={() => {
                  setGeofence([])
                  setMapCenter({ lat: 51.1657, lng: 10.4515 })
                  setMapZoom(5)
                  clearSearch()
                }}>
                {t("settings.fence.clear_fence")}
              </Button>
              {loading ? (
                spinLoader()
              ) : (
                <Button
                  className="btn-primary-outline"
                  onClick={() => {
                    setAlertMsg("")
                    createFence()
                  }}>
                  {fenceUuid ? t("settings.fence.update") : t("settings.fence.create")}
                </Button>
              )}
            </div>
          </Col>
        </div>
      )}
      <ModalView
        title={t("settings.fleets.alert")}
        buttonTitle={t("device_screen.ok")}
        show={showModal}
        buttonLoader={false}
        onHideModal={() => setShowModal(false)}
        onButtonClick={() => {
          if (successMsg) {
            setSuccessMsg(false)
            navigate(-1)
          }
          setShowModal(false)
        }}>
        {!successMsg ? (
          <label>{alertMsg}</label>
        ) : (
          <div className="alert alert-success mt-2" role="alert">
            {fenceUuid ? t("settings.fence.validate.success_edit") : t("settings.fence.validate.success_create")}
          </div>
        )}
      </ModalView>
    </div>
  )
}
