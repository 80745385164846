import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { getDevice } from "@src/api"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { Spinner } from "react-bootstrap"
import { usePartner } from "@src/partner"
import { useFleets } from "@src/settings"

type DeviceSearchProps = {
  searchText: string
  onClick: (device: SLIM_DEVICE_TYPE) => void
}

export const DeviceSearch: React.FC<DeviceSearchProps> = ({ searchText, onClick }) => {
  const { t } = useTranslation()
  const { partner } = usePartner()

  const [loading, setLoading] = useState<boolean>(false)
  const [results, setResults] = useState<SLIM_DEVICE_TYPE[]>([])
  const { fleet } = useFleets()

  useEffect(() => {
    setLoading(true)
    getDevice(partner?.uuid, fleet && fleet.uuid, { search_string: searchText }).then(({ response, data }) => {
      if (response.ok) setResults(data.devices)
      setLoading(false)
    })
  }, [searchText, partner])

  if (loading)
    return (
      <div className="d-flex justify-content-center">
        <Spinner size="sm" animation="border" />
      </div>
    )
  if (results.length == 0)
    return (
      <ul className="list-group">
        <li className="list-group-item">{t("sidebar.no_device_found")}</li>
      </ul>
    )
  return (
    <div className="list-group">
      {results.map((result) => (
        <a
          key={result.serialnumber}
          onClick={() => onClick(result)}
          className="list-group-item list-group-item-action pointer">
          {result.serialnumber} - {result.name}
        </a>
      ))}
    </div>
  )
}
