import React, { Component } from "react"
import { useTranslation } from "react-i18next"

export const ImprintScreen: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="container content">
      <h1>{t("login_screen.imprint")}</h1>
      <p>
        IoT Venture GmbH
        <br />
        c/o Hub 31
        <br />
        Hilpertstr. 31
        <br />
        64295 Darmstadt
        <br />
        Deutschland
        <br />
        <br />
        T: +49 800 0060751
        <br />
        E: info@iot-venture.com
        <br />
        <br />
        {t("imprint.directors")}
        <br />
        Thomas Ullmann, Jürgen Veith
        <br />
        <br />
        {t("imprint.court_number")} HRB-Nr. 95191
        <br />
        {t("imprint.tax_id_number")}: DE306665167
        <br />
        <br />
        {t("imprint.pages_act")}:
        <br />
        <br />
        Jürgen Veith
        <br />
        Geschäftsführer
        <br />
        IoT Venture GmbH
        <br />
        Hilpertstraße 31
        <br />
        64295 Darmstadt
        <br />
        Deutschland
        <br />
        <br />
        {t("imprint.images")}:
        <br />
        {t("imprint.legal_images")} <a href="https://www.gettyimages.de">gettyimages.de</a>
        {t("imprint.verwendet")}.
        <br />
        <br />
        <br />
        <b>{t("imprint.concept")}</b>
        <br />
        SCHUMACHER – Brand + Interaction Design GmbH
        <br />
        <a href="https://www.schumacher-design.de">https://www.schumacher-design.de</a>
        <br />
        <br />
        <br />
        {t("imprint.footer_info")}
        <br />
        <br />
      </p>
    </div>
  )
}
