import React, { createContext, useContext, useEffect, useState } from "react"
import { usePartner } from "@src/partner"
import { useFleets } from "../fleets"
import { FENCE } from "./types"
import { deleteFleetsUuidFenceId, getFleetsUuidFences, putFleetsUuidFenceId, POST_FENCE_BODY } from "@src/api"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { getFleetsUuidFencesIdDevices } from "@src/api"

type FENCE_CONTEXT = {
  fence: FENCE
  fences: FENCE[]
  fenceNavPath: string
  fenceDevices: SLIM_DEVICE_TYPE[]
  loadFences: () => void
  loadFenceDevices: () => void
  loadFenceById: (id: number) => void
  patchFence: (fenceData: POST_FENCE_BODY) => Promise<any>
  deleteFenceById: (id: number) => Promise<any>
  clearFence: () => void
}

const DEFAULT_FENCE_CONTEXT = {
  fence: null,
  fences: [],
  fenceNavPath: "",
  fenceDevices: [],
  loadFences: () => {},
  loadFenceDevices: () => {},
  loadFenceById: (id: number) => {},
  patchFence: async (fenceData: POST_FENCE_BODY) => {},
  deleteFenceById: async (id: number) => {},
  clearFence: () => {},
}

export const FenceContext = createContext<FENCE_CONTEXT>(DEFAULT_FENCE_CONTEXT)

export const FenceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { partner } = usePartner()
  const { fleet } = useFleets()
  const [fence, setFence] = useState<FENCE>()
  const [fences, setFences] = useState<FENCE[]>()
  const [fenceDevices, setFenceDevices] = useState<SLIM_DEVICE_TYPE[]>()
  const [fenceNavPath, setFenceNavPath] = useState<string>("")

  useEffect(() => {
    if (partner && fleet) {
      loadFences()
      setFenceNavPath(`/${partner.uuid}/settings/fleets/${fleet.uuid}/geofences`)
    }
  }, [partner, fleet])

  useEffect(() => {
    if (fence) {
      loadFenceDevices()
    }
  }, [fence])

  const loadFences = () => {
    getFleetsUuidFences(partner.uuid, fleet.uuid).then(({ response, data }) => {
      if (response.ok) setFences(data.fences)
    })
  }

  const loadFenceById = (id: number) => {
    //TODO:- Fence API by id is still a dependency
    if (fences) {
      let fencesDetail = fences.filter((item) => item.id == id)
      setFence(fencesDetail[0])
    }
  }

  const loadFenceDevices = () => {
    if (fence)
      getFleetsUuidFencesIdDevices(partner.uuid, fleet.uuid, fence.id).then(({ response, data }) => {
        if (response.ok) {
          setFenceDevices(data.devices)
        }
      })
  }

  const patchFence = async (fenceData: POST_FENCE_BODY) => {
    if (partner && fleet && fence) {
      let { response, data } = await putFleetsUuidFenceId(partner.uuid, fleet.uuid, fence.id, fenceData)
      loadFences()
      return response
    }
  }

  const deleteFenceById = async (id: number) => {
    let { response, data } = await deleteFleetsUuidFenceId(partner.uuid, fleet.uuid, id)
    if (response.ok) loadFences()
    return response
  }

  const clearFence = () => {
    setFence(null)
  }

  const context = {
    fence,
    fences,
    fenceNavPath,
    fenceDevices,
    loadFences,
    loadFenceDevices,
    loadFenceById,
    patchFence,
    deleteFenceById,
    clearFence,
  }

  return <FenceContext.Provider value={context}>{children}</FenceContext.Provider>
}

export const useFence = () => useContext(FenceContext)
