import React from "react"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import "./App.css"
import ApplicationRouter from "./routes/ApplicationRouter"

import Footer from "./components/Footer"
import { NavBar } from "./components"

Sentry.init({
  dsn: "https://cfac4fa025204e6bbde7bdd539cef32d@o100561.ingest.sentry.io/6698472",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Enabled Sentry log in development mode
  // debug: true,
})

const App: React.FC = () => {
  return (
    <div>
      <NavBar />

      <div id="login-background-div" style={{ minHeight: "100vh" }} className="login-background">
        <ApplicationRouter />
      </div>

      <div className="login-background" style={{}}></div>

      <Footer />
    </div>
  )
}

export default App
