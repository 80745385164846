import { usePartner } from "@src/partner"
import React from "react"

type RadialChartProps = {
  totalValue: number
  value: number
  size: number
  strokeWidth: number
}

const RadialChart: React.FC<RadialChartProps> = ({ totalValue, value, size, strokeWidth }) => {
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (value / totalValue) * circumference
  const { brand } = usePartner()

  return (
    <svg width={size} height={size}>
      {/* Background circle */}
      <circle cx={size / 2} cy={size / 2} r={radius} stroke="lightgrey" strokeWidth={strokeWidth} fill="none" />
      {/* Progress circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="#FF7675"
        strokeWidth={strokeWidth}
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
      {/* Percentage text in the middle */}
      <text x="50%" y="50%" dy=".3em" textAnchor="middle" fontSize="30px" fontWeight="bold" fill={brand?.color}>
        {value}
      </text>
    </svg>
  )
}

export default RadialChart
