import { InputField, Page } from "@src/components"
import React, { useEffect, useState } from "react"
import { Tabs, Tab, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { DevicesSelectTab, SearchDeviceType } from "../components"
import { FenceDetailTab } from "./tabs/FenceDetailTab"
import { useFence } from "./useFence"

const TABS = ["fence", "device"]

export const EditFenceScreen: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab")
  const [loadingField, setLoadingField] = useState<string>("")
  const { fence, patchFence, fenceDevices, loadFenceDevices } = useFence()
  const [key, setKey] = useState<string>("fence")

  useEffect(() => {
    if (!TABS.includes(tab)) setSearchParams({ tab: "fence" }, { replace: true })
  }, [])

  const onInputPersist = (field: string) => async (text: string) => {
    setLoadingField(field)
    let body = { fence: { kind: "classic", fencingposts: fence.fencingposts, name: text } }
    let response = await patchFence(body)
    if (response.ok) setLoadingField("")
  }

  return (
    <Page isSettings={true}>
      <div>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <InputField
              value={fence && fence.name}
              onPersist={onInputPersist("name")}
              loading={loadingField == "name"}
            />
          </Col>
        </Row>
        <Tabs
          activeKey={tab}
          onSelect={(key) => {
            setKey(key)
            setSearchParams({ tab: key })
          }}
          className="mb-3">
          <Tab eventKey={TABS[0]} title={t("settings.fence.tabs.fence")}>
            {key == "fence" && <FenceDetailTab />}
          </Tab>
        </Tabs>
      </div>
    </Page>
  )
}
