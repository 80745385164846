import { callApi } from "../callApi"
import { GET_DEVICE_PARAMS, GET_DEVICE_RESPONSE } from "./getDevice"

export const getFleetsUuidFencesIdDevices = async (
  partnerUuid: string,
  fleetUuid: string,
  fenceId: number,
  params?: GET_DEVICE_PARAMS,
) => {
  const queryString = Object.keys(params || {})
    .map((key) => key + "=" + params[key])
    .join("&")
  return callApi<null, GET_DEVICE_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/fences/${fenceId}/devices?${queryString}`,
  })
}
