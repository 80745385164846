import { localization } from "@src/config"

export const validateLanguage = (lang: string) => {
  if (localization.languages.includes(lang)) return lang
  return localization.defaultLang
}

export const validateRegion = (region: string) => {
  if (localization.regions.includes(region)) return region
  return localization.defaultRegion
}
